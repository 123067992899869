<template>
  <v-row style="width: 300px">
    <v-col>
      <v-img
        :src="heatmapScale"
        :max-width="250"
        contain
        position="center center"
        class="ml-1"
      />
      <v-row class="title-font">
        <v-col class="pb-0"> 0 </v-col>
        <v-col class="pb-0"> 20 </v-col>
        <v-col class="pb-0"> 50 </v-col>
        <v-col class="pb-0"> 80 </v-col>
        <v-col class="pb-0"> 100+ </v-col>
      </v-row>
      <v-row>
        <v-col class="title-font text-center pt-0">
          MICRONS (0.001<small>mm</small>)
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import heatmapScale from "@/assets/heatmap-scale.svg"
export default {
  name: "HeatmapScale",

  data() {
    return {
      heatmapScale
    }
  }
}
</script>
