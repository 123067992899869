<template>
  <v-fade-transition>
    <v-container fluid class="fill-height ma-0 pa-0" v-show="!transition">
      <v-row class="fill-height ma-0 pa-0">
        <v-col class="fill-height ma-0 pa-0">
          <v-card class="fill-height rounded-10">
            <div ref="scanValidationView"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-fade-transition>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"

export default {
  name: "ValidatedDiv",

  scanValidationView: null,

  mounted() {
    this.scanValidationView = NCFrontend.createView(
      "scanValidationView",
      this.$refs.scanValidationView
    )
    NCFrontend.add(this.scanValidationView)
    this.scanValidationView.setVisualisation(
      new NCFrontend.Visualisations.ScanValidation(
        this.scanValidationView.element,
        {
          fixedScan: this.validateScansResponse.fixedScan,
          scanAnalysis: this.validateScansResponse.scanAnalysis,
          immediateCase: false
        }
      )
    )
    setTimeout(() => {
      this.setTransition(false)
    }, 300)
  },

  unmounted() {
    NCFrontend.remove(this.scanValidationView)
  },

  methods: {
    ...mapActions(["setTransition"])
  },

  computed: {
    ...mapGetters(["validateScansResponse", "transition"])
  }
}
</script>
