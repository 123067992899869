<template>
  <div>
    <span class="primary--text">
      Ensure you select the correct serial number, matching the kit used to
      scan.
    </span>
    <ScanGaugeKitSelector v-model="kit" :kits="kits" :loading="loading" />
    <v-row>
      <v-col md="8" sm="12">
        <span class="grey--text text--lighten-1 text-caption">
          Not listed? Contact your lab to ensure the kit license is activated.
        </span>
      </v-col>
      <v-col md="4" sm="12" class="text-right">
        <v-btn class="btn-primary ml-3" @click="$emit('next')" :disabled="!kit">
          Continue
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ScanGaugeKitSelector from "./ChooseKit/ScanGaugeKitSelector.vue"

export default {
  name: "ChooseKit",

  components: { ScanGaugeKitSelector },

  data() {
    return {
      loading: false,
      kits: []
    }
  },

  computed: {
    ...mapGetters(["orderDetails"]),

    mappedKits() {
      return this.kits.map((k) => {
        return k.kit_box_id
        // return {
        //   value: k.uid,
        //   text: k.kit_box_id
        // }
      })
    },

    kit: {
      get() {
        return this.orderDetails.kit_box_id
      },
      set(value) {
        this.setOrderDetails({ ...this.orderDetails, kit_box_id: value })
      }
    }
  },

  methods: {
    ...mapActions(["setOrderDetails"]),

    async fetchGaugeKits() {
      this.loading = true
      this.kits = await client.instance.tenants.fetchTenantKits()
      this.loading = false
    }
  },

  beforeMount() {
    this.fetchGaugeKits()
  }
}
</script>
