<template>
  <div>
    <span class="primary--text"> Fill out and submit your order form. </span>
  </div>
</template>

<script>
export default {
  name: "RxForm"
}
</script>
