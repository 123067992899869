<template>
  <svg
    width="65"
    height="25"
    viewBox="0 0 65 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9453 8.23633C29.8891 8.23633 32.2792 10.6264 32.2792 13.5702V20.6192C32.2792 22.4858 30.7638 24.0013 28.8972 24.0013H25.247C22.9856 24.0013 21.6113 22.1653 21.6113 19.9038V13.5703C21.6113 10.6264 24.0014 8.23633 26.9453 8.23633Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0464 9.82544C20.014 9.82544 21.6114 11.4229 21.6114 13.3904V19.5258C21.6114 21.1189 20.3181 22.4123 18.725 22.4123C18.725 22.4123 14.4814 22.4217 14.4814 19.8857V13.3904C14.4814 11.4229 16.0789 9.82544 18.0464 9.82544Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.94101 22.0471C6.94101 22.0471 5.08008 21.3659 5.08008 20.5257V13.2978C5.08008 12.4575 5.84301 11.7764 6.78414 11.7764H6.94101C7.88214 11.7764 8.64508 12.4575 8.64508 13.2978V20.5257C8.64508 21.3659 7.88214 22.0471 6.94101 22.0471Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.03193 20.9468C4.03193 20.9468 2.83301 20.6599 2.83301 20.3059V13.4804C2.83301 13.1265 3.30238 12.3982 3.88139 12.3982H4.03193C4.61094 12.3982 5.08031 12.6851 5.08031 13.0391V20.3059C5.08031 20.6599 4.61094 20.9468 4.03193 20.9468Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.89261 20.0858C1.89261 20.0858 1 19.5837 1 19.1451V14.4602C1 14.0216 1.39964 13.666 1.89261 13.666C2.38559 13.666 2.78523 14.0216 2.78523 14.4602V19.1451C2.78523 19.5837 2.38559 20.0858 1.89261 20.0858Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.7452 22.5493C12.1182 23.0161 11.2056 22.9466 10.6446 22.4127C9.83682 21.6441 8.64502 20.3431 8.64502 19.3571V13.1106C8.64502 11.6193 9.71958 10.4104 11.5631 10.4104C13.1748 10.4104 14.4812 11.6193 14.4812 13.1106V19.3571C14.4812 20.4042 13.7429 21.8066 12.7452 22.5493Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.39723 13.666C1.39723 13.666 0.943605 10.5093 3.23968 10.5093C3.27385 10.5093 3.31269 10.4596 3.36396 10.3726"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.3608 6.33341C10.8782 6.19204 11.5664 6.07708 12.4876 6.02271"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.6613 24.0013C33.7947 24.0013 32.2793 22.4858 32.2793 20.6192V13.5702C32.2793 10.6264 34.6693 8.23633 37.6132 8.23633C40.5571 8.23633 42.9471 10.6264 42.9471 13.5702V19.9038C42.9471 22.1653 41.5729 24.0013 39.3115 24.0013H35.6613Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8337 22.4123C44.2407 22.4123 42.9473 21.1189 42.9473 19.5258V13.3904C42.9473 11.4229 44.5447 9.82544 46.5123 9.82544C48.4798 9.82544 50.0773 11.4229 50.0773 13.3904V19.8857C50.0773 22.0472 45.8337 22.4123 45.8337 22.4123Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.6176 22.0471C57.6176 22.0471 59.4786 21.3659 59.4786 20.5257V13.2978C59.4786 12.4575 58.7156 11.7764 57.7745 11.7764H57.6176C56.6765 11.7764 55.9136 12.4575 55.9136 13.2978V20.5257C55.9136 21.3659 56.6765 22.0471 57.6176 22.0471Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.5269 20.9468C60.5269 20.9468 61.7258 20.6599 61.7258 20.3059V13.4804C61.7258 13.1265 61.2565 12.3982 60.6774 12.3982H60.5269C59.9479 12.3982 59.4785 12.6851 59.4785 13.0391V20.3059C59.4785 20.6599 59.9479 20.9468 60.5269 20.9468Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.666 20.0858C62.666 20.0858 63.5587 19.5837 63.5587 19.1451V14.4602C63.5587 14.0216 63.159 13.666 62.666 13.666C62.1731 13.666 61.7734 14.0216 61.7734 14.4602V19.1451C61.7734 19.5837 62.1731 20.0858 62.666 20.0858Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.8131 22.5493C52.4402 23.0161 53.3528 22.9466 53.9138 22.4127C54.7216 21.6441 55.9134 20.3431 55.9134 19.3571V13.1106C55.9134 11.6193 55.0989 10.4104 52.9953 10.4104C51.3836 10.4104 50.0771 11.6193 50.0771 13.1106V19.3571C50.0771 20.4042 50.8155 21.8066 51.8131 22.5493Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.0142 3.86493C41.9278 3.63601 40.2108 3.35317 38.091 3.36132C35.3803 3.37175 34.9267 3.84682 32.293 3.79985C29.4743 3.74958 29.4555 3.19579 26.6648 3.2772C25.134 3.32185 23.5587 3.53459 22.4263 3.68751C21.2171 3.85082 20.2093 4.02488 19.4849 4.1601"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.5679 8.44629C60.8775 9.32868 61.154 10.5093 61.3187 10.5093C63.6147 10.5093 63.1611 13.666 63.1611 13.666"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.0107 5.9126C50.2344 5.96076 50.4939 5.98872 50.8186 5.98872C51.4182 5.98872 51.9433 6.00891 52.4047 6.0462"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.4848 2.17645V4.16027C15.2422 4.92304 15.7672 5.98875 13.7399 5.98875C13.2801 5.98875 12.8653 6.00117 12.4878 6.02292V2.17645C13.2677 1.90459 14.484 1.58146 15.9987 1.58301C17.5009 1.58457 18.708 1.90459 19.4848 2.17645Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.0111 1.43066V5.91251C48.644 5.61268 48.5974 4.50194 43.0142 3.865V1.43066C43.9012 1.20851 45.0896 1.00034 46.4971 0.998788C47.9186 0.997242 49.1179 1.20696 50.0111 1.43066Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.5681 8.44623C58.7852 7.91805 57.6325 7.49549 55.9128 7.49549C55.7232 7.49549 55.8677 6.32261 52.405 6.04609L52.3506 4.80018C53.2609 4.60288 54.5985 4.48481 55.8335 4.46462C57.3731 4.43821 57.9292 4.52831 59.3538 4.79086L59.5681 8.44623Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.3607 5.76023V6.33347C8.73733 6.77777 8.79325 7.49549 8.64567 7.49549C4.64385 7.49549 3.7102 9.7838 3.36377 10.3726V6.00258C4.60036 5.70586 5.85092 5.48682 7.14033 5.48682C8.2138 5.48682 9.28726 5.59245 10.3607 5.76023Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SingleArch"
}
</script>
