<template>
  <v-btn
    width="100%"
    height="86"
    v-ripple
    outlined
    :color="dragOver ? 'primary' : ''"
    class="rounded-10 fill-height py-1 grey-border"
    style="cursor: pointer; user-select: none; background-color: #f5f5f5"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @click="addFile()"
    :disabled="disabled || loading"
  >
    <div class="d-flex flex-column">
      <v-progress-circular indeterminate v-if="loading" />
      <span v-if="!loading">
        <v-icon class="mr-1">$upload</v-icon>
        UPLOAD
      </span>
      <i v-if="!loading" style="font-size: 10px; text-transform: none">
        Click or Drag and Drop to upload
      </i>
      <i
        v-if="!loading && (invalidFile || maxFilesExceeded)"
        style="font-size: 10px; text-transform: none"
        class="error--text"
      >
        <span v-if="maxFilesExceeded"> Only one file can be uploaded. </span>
        <span v-if="invalidFile && fileExtension.length === 1">
          File type must be .{{ fileExtension[0] }}
        </span>
        <span v-if="invalidFile && fileExtension.length > 1">
          File type must be one of:
          <span v-for="(ext, index) in fileExtension" :key="index">
            <span v-if="index !== 0">, </span>.{{ ext }}
          </span>
        </span>
      </i>
    </div>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import clientOptions from "@/plugins/axios/client_options"

export default {
  name: "FileUploader",

  props: ["text", "fileExtension", "value", "category", "disabled", "newOrder"],

  watch: {
    value(value) {
      if (value) {
        this.invalidFile = false
        this.filesToUpload = []
      }
    }
  },

  data() {
    return {
      loading: false,
      dragOver: false,
      filesToUpload: [],
      filesUploaded: [],
      uploadProgress: 0,
      inputDOMElement: null,
      invalidFile: false,
      maxFilesExceeded: false
    }
  },

  computed: {
    ...mapGetters(["orderUid", "country"])
  },

  methods: {
    fileExtensionCheck(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      return this.fileExtension.includes(ext)
    },

    fileType(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      if (["stl", "ply"].includes(ext)) return "scan"
      if (["png", "jpg", "jpeg"].includes(ext)) return "photo"
      else return "other"
    },

    async addFile() {
      this.maxFilesExceeded = false
      this.invalidFile = false
      if (this.inputDOMElement) {
        this.inputDOMElement.value = ""
      } else {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = false
        this.inputDOMElement.addEventListener("change", async (event) => {
          if (event.target.files.length > 1) {
            this.maxFilesExceeded = true
            return
          }

          const file = event.target.files[0]
          if (!this.fileExtensionCheck(file)) {
            this.invalidFile = true
            return
          }

          if (this.newOrder) this.$emit("input", file)
          else {
            this.loading = true
            await this.handleFileUpload(file)
            this.loading = false
          }
        })
      }
      this.inputDOMElement.click()
    },

    async onDrop(event) {
      this.dragOver = false
      this.maxFilesExceeded = false
      this.invalidFile = false

      if (event.dataTransfer.files.length > 1) {
        this.maxFilesExceeded = true
        return
      }

      const file = event.dataTransfer.files[0]
      if (!this.fileExtensionCheck(file)) {
        this.invalidFile = true
        return
      }

      if (this.newOrder) this.$emit("input", file)
      else {
        this.loading = true
        await this.handleFileUpload(file)
        this.loading = false
      }
    },

    async handleFileUpload(file) {
      const oftResponse = await this.oftAddFile(file)

      await this.uploadToGoogleStorage(
        file,
        oftResponse.data[0].oneTimeUploadLink
      )

      let payload = []
      payload.push({
        file_name: oftResponse.data[0].fileName,
        file_category: this.category,
        file_type: this.fileType(file),
        oft_file_uid: oftResponse.data[0].uid
      })

      const response = await client.instance.orders.addFiles(
        this.orderUid,
        payload
      )
      this.$emit("input", response.data[0])
    },

    async oftAddFile(file) {
      try {
        const response = await this.$axios.post(
          `${clientOptions.oftURL}/upload/${this.country}`,
          [
            {
              fileName: file.name,
              fileReferences: [
                {
                  entityType: "NCLABS_ORDER",
                  entityIdentification: this.orderUid
                }
              ]
            }
          ]
        )
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async uploadToGoogleStorage(file, uploadLink) {
      try {
        const response = await fetch(uploadLink, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream"
          },
          body: file
        })
        return response
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
