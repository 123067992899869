<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 680 1080"
    style="enable-background: new 0 0 680 1080"
    xml:space="preserve"
    id="svg910"
    sodipodi:docname="Nexus-io-implant-selection.svg"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
  >
    <metadata id="metadata916">
      <rdf:RDF
        ><cc:Work rdf:about=""
          ><dc:format>image/svg+xml</dc:format
          ><dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title /></cc:Work
      ></rdf:RDF>
    </metadata>
    <defs id="defs914">
      <linearGradient
        :id="`t-${uniqueKey}-26-fill`"
        gradientUnits="userSpaceOnUse"
        x1="498.19299"
        y1="393.72672"
        x2="587.84692"
        y2="393.72672"
      >
        <stop offset="0" :style="getCustomColor(26)[0]" id="stop708" />
        <stop offset="1" :style="getCustomColor(26)[1]" id="stop710" />
      </linearGradient>
      <linearGradient
        :id="`t-${uniqueKey}-26-stroke`"
        gradientUnits="userSpaceOnUse"
        x1="497.69299"
        y1="393.72672"
        x2="588.34692"
        y2="393.72672"
      >
        <stop offset="0" style="stop-color: #30a4e0" id="stop713" />
        <stop offset="1" style="stop-color: #0071ff" id="stop715" />
      </linearGradient>
      <path
        id="mdi-circle-outline"
        d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
        style="fill: #000000"
      ></path>
    </defs>
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="2064"
      inkscape:window-height="1312"
      id="namedview912"
      showgrid="false"
      inkscape:pagecheckerboard="true"
      inkscape:zoom="1.0166666"
      inkscape:cx="57.528311"
      inkscape:cy="635.36147"
      inkscape:window-x="-9"
      inkscape:window-y="0"
      inkscape:window-maximized="0"
      inkscape:current-layer="svg910"
    />
    <g id="Words">
      <text
        x="280"
        y="355"
        font-weight="bold"
        font-size="2.2rem"
        :style="fillColor"
      >
        UPPER
      </text>
      <text
        x="280"
        y="755"
        font-weight="bold"
        font-size="2.2rem"
        :style="fillColor"
      >
        LOWER
      </text>
    </g>
    <g id="g11" inkscape:label="g11">
      <linearGradient
        y2="148.1221"
        x2="338.63159"
        y1="148.1221"
        x1="268.2756"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-11-fill`"
      >
        <stop id="stop504" :style="getCustomColor(11)[0]" offset="0" />
        <stop id="stop506" :style="getCustomColor(11)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="148.1221"
        x2="339.13159"
        y1="148.1221"
        x1="267.7756"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-11-stroke`"
      >
        <stop id="stop509" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop511" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-11-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-11-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 334.9472,108.196 c -3.9572,-9.5882 -66.108,4.0985 -66.6351,18.5691 -0.8479,23.2785 13.267,49.9983 24.7543,59.9896 11.4873,9.9913 38.3915,2.3956 41.6319,-13.7938 3.2404,-16.1895 6.8153,-48.855 0.2489,-64.7649 z"
        class="st2"
        id="t11"
        inkscape:label="t11"
      />
      <text
        x="290"
        y="160"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('11')"
      >
        {{ getToothNumber(11) }}
      </text>
    </g>
    <g id="g12" inkscape:label="g12">
      <linearGradient
        y2="168.7345"
        x2="276.87189"
        y1="168.7345"
        x1="211.0605"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-12-fill`"
      >
        <stop id="stop521" :style="getCustomColor(12)[0]" offset="0" />
        <stop id="stop523" :style="getCustomColor(12)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="168.7345"
        x2="277.37189"
        y1="168.7345"
        x1="210.5605"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-12-stroke`"
      >
        <stop id="stop526" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop528" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-12-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-12-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 255.9964,130.1317 c -9.6079,-3.9057 -51.5165,19.912 -44.0499,35.9342 8.6553,18.5728 31.3328,38.6246 44.4693,41.2888 13.1364,2.6642 21.2321,-8.0733 20.3972,-21.2222 -0.6222,-9.8 -9.3241,-51.329 -20.8166,-56.0008 z"
        class="st3"
        id="t12"
        inkscape:label="t12"
      />
      <text
        x="225"
        y="180"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('12')"
      >
        {{ getToothNumber(12) }}
      </text>
    </g>
    <g id="g13" inkscape:label="g13">
      <linearGradient
        y2="212.0961"
        x2="243.0249"
        y1="212.0961"
        x1="171.79671"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-13-fill`"
      >
        <stop id="stop538" :style="getCustomColor(13)[0]" offset="0" />
        <stop id="stop540" :style="getCustomColor(13)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="212.0961"
        x2="243.5249"
        y1="212.0961"
        x1="171.29671"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-13-stroke`"
      >
        <stop id="stop543" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop545" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-13-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-13-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 211.0605,174.2624 c -10.7948,-2.4288 -22.1792,2.5778 -29.8934,13.5529 -8.59,12.2211 -12.6292,31.0206 -6.2362,38.0582 14.0661,15.4843 39.5692,25.754 53.0596,24.5642 13.4904,-1.1898 17.993,-13.8982 13.1397,-26.3783 -3.6172,-9.3015 -17.794,-47.035 -30.0697,-49.797 z"
        class="st4"
        id="t13"
        inkscape:label="t13"
      />
      <text
        x="185"
        y="225"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('13')"
      >
        {{ getToothNumber(13) }}
      </text>
    </g>
    <g id="g14" inkscape:label="g14">
      <linearGradient
        y2="265.46939"
        x2="218.0238"
        y1="265.46939"
        x1="145.2002"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-14-fill`"
      >
        <stop id="stop555" :style="getCustomColor(14)[0]" offset="0" />
        <stop id="stop557" :style="getCustomColor(14)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="265.46939"
        x2="218.5238"
        y1="265.46939"
        x1="144.7002"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-14-stroke`"
      >
        <stop id="stop560" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop562" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-14-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-14-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 145.2002,273.3245 c 0,15.0078 34.5974,22.7931 46.1095,22.7931 11.5121,0 20.6368,-3.4298 25.6146,-20.2298 4.9778,-16.8 -7.9948,-23.8511 -17.8888,-29.5555 -9.0481,-5.2167 -17.3505,-11.5111 -26.4444,-11.5111 -22.0287,0 -27.3909,26.9912 -27.3909,38.5033 z"
        class="st5"
        id="t14"
        inkscape:label="t14"
      />
      <text
        x="160"
        y="280"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('14')"
      >
        {{ getToothNumber(14) }}
      </text>
    </g>
    <g id="g15" inkscape:label="g15">
      <linearGradient
        y2="319.45639"
        x2="200.8011"
        y1="319.45639"
        x1="123.1652"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-15-fill`"
      >
        <stop id="stop572" :style="getCustomColor(15)[0]" offset="0" />
        <stop id="stop574" :style="getCustomColor(15)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="319.45639"
        x2="201.3011"
        y1="319.45639"
        x1="122.6652"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-15-stroke`"
      >
        <stop id="stop577" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop579" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-15-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-15-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 152.3841,351.3931 c 15.8185,2.5401 50.8624,-1.3128 48.2819,-25.7262 -1.8364,-17.3743 -20.6537,-27.4238 -34.6441,-32.8419 -6.4118,-2.4831 -12.6171,-7.1363 -19.4571,-5.7111 -8.5175,1.7747 -20.4577,14.4406 -23.0297,27.0824 -2.572,12.6418 8.3967,33.9126 28.849,37.1968 z"
        class="st6"
        id="t15"
        inkscape:label="t15"
      />
      <text
        x="140"
        y="335"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('15')"
      >
        {{ getToothNumber(15) }}
      </text>
    </g>
    <g id="g16" inkscape:label="g16">
      <linearGradient
        y2="393.72672"
        x2="181.8071"
        y1="393.72672"
        x1="92.153099"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-16-fill`"
      >
        <stop id="stop589" :style="getCustomColor(16)[0]" offset="0" />
        <stop id="stop591" :style="getCustomColor(16)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="393.72672"
        x2="182.3071"
        y1="393.72672"
        x1="91.653099"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-16-stroke`"
      >
        <stop id="stop594" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop596" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-16-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-16-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 94.842,408.1762 c 9.0714,25.2112 63.4453,39.9876 73.0605,30.0865 16.744,-17.2419 15.6849,-52.6779 10.6322,-65.0445 -5.0527,-12.3666 -45.1444,-30.5694 -56.9277,-26.5706 -11.7834,3.9988 -37.8609,30.6907 -26.765,61.5286 z"
        class="st7"
        id="t16"
        inkscape:label="t16"
      />
      <text
        x="120"
        y="405"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('16')"
      >
        {{ getToothNumber(16) }}
      </text>
    </g>
    <g id="g17" inkscape:label="g17">
      <linearGradient
        y2="476.96701"
        x2="169.97639"
        y1="476.96701"
        x1="82.875504"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-17-fill`"
      >
        <stop id="stop606" :style="getCustomColor(17)[0]" offset="0" />
        <stop id="stop608" :style="getCustomColor(17)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="476.96701"
        x2="170.47639"
        y1="476.96701"
        x1="82.375504"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-17-stroke`"
      >
        <stop id="stop611" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop613" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-17-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-17-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 82.9055,479.2303 c 0.2768,14.0551 4.5736,24.1369 14.3248,30.3705 5.6646,3.6212 15.9206,3.0599 22.4244,4.6507 6.0758,1.4861 11.3284,4.7311 16.6738,4.8229 6.8811,0.1182 12.5716,-0.7677 15.5645,-2.2685 19.7853,-9.9212 23.5071,-48.2647 10.3163,-63.3677 -7.1325,-8.1665 -47.7908,-22.6602 -59.6311,-17.5075 -11.8403,5.1525 -20.272,12.8655 -19.6727,43.2996 z"
        class="st8"
        id="t17"
        inkscape:label="t17"
      />
      <text
        x="110"
        y="490"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('17')"
      >
        {{ getToothNumber(17) }}
      </text>
    </g>
    <g id="g21" inkscape:label="g21">
      <linearGradient
        y2="148.1221"
        x2="411.7244"
        y1="148.1221"
        x1="340.35251"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-21-fill`"
      >
        <stop id="stop623" :style="getCustomColor(21)[0]" offset="0" />
        <stop id="stop625" :style="getCustomColor(21)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="148.1221"
        x2="412.2244"
        y1="148.1221"
        x1="339.85251"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-21-stroke`"
      >
        <stop id="stop628" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop630" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-21-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-21-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 344.0901,108.196 c 4.0144,-9.5882 67.0625,4.0985 67.5973,18.5691 0.8602,23.2786 -13.4586,49.9983 -25.1117,59.9896 -11.6532,9.9913 -38.9459,2.3956 -42.233,-13.7938 -3.2871,-16.1894 -6.9137,-48.855 -0.2526,-64.7649 z"
        class="st9"
        id="t21"
        inkscape:label="t21"
      />
      <text
        x="355"
        y="160"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('21')"
      >
        {{ getToothNumber(21) }}
      </text>
    </g>
    <g id="g22" inkscape:label="g22">
      <linearGradient
        y2="168.7345"
        x2="468.93951"
        y1="168.7345"
        x1="403.12811"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-22-fill`"
      >
        <stop id="stop640" :style="getCustomColor(22)[0]" offset="0" />
        <stop id="stop642" :style="getCustomColor(22)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="168.7345"
        x2="469.43951"
        y1="168.7345"
        x1="402.62811"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-22-stroke`"
      >
        <stop id="stop645" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop647" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-22-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-22-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 424.0036,130.1317 c 9.6079,-3.9057 51.5165,19.912 44.0499,35.9342 -8.6553,18.5728 -31.3328,38.6246 -44.4693,41.2888 -13.1364,2.6642 -21.2321,-8.0733 -20.3972,-21.2222 0.6222,-9.8 9.3241,-51.329 20.8166,-56.0008 z"
        class="st10"
        id="t22"
        inkscape:label="t22"
      />
      <text
        x="415"
        y="180"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('22')"
      >
        {{ getToothNumber(22) }}
      </text>
    </g>
    <g id="g23" inkscape:label="g23">
      <linearGradient
        y2="212.0939"
        x2="508.20331"
        y1="212.0939"
        x1="436.9751"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-23-fill`"
      >
        <stop id="stop657" :style="getCustomColor(23)[0]" offset="0" />
        <stop id="stop659" :style="getCustomColor(23)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="212.0939"
        x2="508.70331"
        y1="212.0939"
        x1="436.4751"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-23-stroke`"
      >
        <stop id="stop662" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop664" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-23-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-23-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 468.9395,174.2624 c 10.6958,-2.4065 21.9704,2.424 29.6802,13.2516 8.736,12.2686 12.8951,31.264 6.4494,38.3596 -14.0661,15.4843 -39.5692,25.754 -53.0597,24.5642 -13.4904,-1.1898 -17.993,-13.8982 -13.1396,-26.3783 3.6172,-9.3016 17.794,-47.0351 30.0697,-49.7971 z"
        class="st11"
        id="t23"
        inkscape:label="t23"
      />
      <text
        x="455"
        y="225"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('23')"
      >
        {{ getToothNumber(23) }}
      </text>
    </g>
    <g id="g24" inkscape:label="g24">
      <linearGradient
        y2="265.46939"
        x2="534.79993"
        y1="265.46939"
        x1="461.9762"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-24-fill`"
      >
        <stop id="stop674" :style="getCustomColor(24)[0]" offset="0" />
        <stop id="stop676" :style="getCustomColor(24)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="265.46939"
        x2="535.29993"
        y1="265.46939"
        x1="461.4762"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-24-stroke`"
      >
        <stop id="stop679" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop681" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-24-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-24-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 534.7999,273.3245 c 0,15.0078 -34.5975,22.7931 -46.1095,22.7931 -11.5121,0 -20.6368,-3.4298 -25.6146,-20.2298 -4.9778,-16.8 7.9948,-23.8511 17.8889,-29.5555 9.0481,-5.2167 17.3505,-11.5111 26.4444,-11.5111 22.0285,0 27.3908,26.9912 27.3908,38.5033 z"
        class="st12"
        id="t24"
        inkscape:label="t24"
      />
      <text
        x="480"
        y="275"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('24')"
      >
        {{ getToothNumber(24) }}
      </text>
    </g>
    <g id="g25" inkscape:label="g25">
      <linearGradient
        y2="319.45639"
        x2="556.83478"
        y1="319.45639"
        x1="479.19891"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-25-fill`"
      >
        <stop id="stop691" :style="getCustomColor(25)[0]" offset="0" />
        <stop id="stop693" :style="getCustomColor(25)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="319.45639"
        x2="557.33478"
        y1="319.45639"
        x1="478.69891"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-25-stroke`"
      >
        <stop id="stop696" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop698" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-25-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-25-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 556.4648,314.1962 c -2.5719,-12.6418 -14.5121,-25.3077 -23.0296,-27.0824 -6.84,-1.4252 -13.0453,3.228 -19.4571,5.7111 -13.9904,5.4182 -32.8077,15.4677 -34.6442,32.8419 -2.5804,24.4134 32.4634,28.2663 48.2819,25.7262 20.4524,-3.2841 31.421,-24.555 28.849,-37.1968 z"
        class="st13"
        id="t25"
        inkscape:label="t25"
      />
      <text
        x="500"
        y="335"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('25')"
      >
        {{ getToothNumber(25) }}
      </text>
    </g>
    <g id="g26" inkscape:label="g26">
      <path
        id="t26"
        class="st14"
        d="m 585.158,408.1762 c -9.0714,25.2112 -63.4453,39.9876 -73.0605,30.0865 -16.744,-17.2419 -15.6849,-52.6779 -10.6322,-65.0445 5.0527,-12.3666 45.1444,-30.5694 56.9278,-26.5706 11.7832,3.9988 37.8608,30.6907 26.7649,61.5286 z"
        :style="`fill:url(#t-${uniqueKey}-26-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-26-stroke);stroke-miterlimit:10;stroke-width:4px`"
        inkscape:connector-curvature="0"
        inkscape:label="t26"
      />
      <text
        x="525"
        y="405"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('26')"
      >
        {{ getToothNumber(26) }}
      </text>
    </g>
    <g id="g27" inkscape:label="g27">
      <linearGradient
        y2="476.96701"
        x2="597.12451"
        y1="476.96701"
        x1="510.02359"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-27-fill`"
      >
        <stop id="stop726" :style="getCustomColor(27)[0]" offset="0" />
        <stop id="stop728" :style="getCustomColor(27)[1]" offset="1" />
      </linearGradient>
      <linearGradient
        y2="476.96701"
        x2="597.62451"
        y1="476.96701"
        x1="509.52359"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-27-stroke`"
      >
        <stop id="stop731" style="stop-color: #30a4e0" offset="0" />
        <stop id="stop733" style="stop-color: #0071ff" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-27-fill);fill-opacity:0;stroke:url(#t-${uniqueKey}-27-stroke);stroke-miterlimit:10;stroke-width:4px`"
        d="m 597.0945,479.2303 c -0.2768,14.0551 -4.5736,24.1369 -14.3248,30.3705 -5.6646,3.6212 -15.9207,3.0599 -22.4244,4.6507 -6.0758,1.4861 -11.3284,4.7311 -16.6738,4.8229 -6.8811,0.1182 -12.5716,-0.7677 -15.5645,-2.2685 -19.7853,-9.9212 -23.5071,-48.2647 -10.3163,-63.3677 7.1325,-8.1665 47.7908,-22.6602 59.6311,-17.5075 11.8403,5.1525 20.272,12.8655 19.6727,43.2996 z"
        class="st15"
        id="t27"
        inkscape:label="t27"
      />
      <text
        x="535"
        y="490"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('27')"
      >
        {{ getToothNumber(27) }}
      </text>
    </g>
    <g id="g31" inkscape:label="g31">
      <linearGradient
        y2="942.22198"
        x2="386.1438"
        y1="942.22198"
        x1="341.16699"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-31-fill`"
      >
        <stop id="stop743" :style="getCustomColor(31)[0]" offset="0" />
        <stop id="stop745" :style="getCustomColor(31)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-31-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 357.2096,910.2396 c -9.8701,0.9946 -17.8469,30.5365 -15.6867,51.7698 2.1602,21.2333 47.9494,11.4954 44.4277,-3.9805 -3.5217,-15.4759 -14.799,-49.1942 -28.741,-47.7893 z"
        class="st16"
        id="t31"
        inkscape:label="t31"
      />
      <text
        x="345"
        y="960"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('31')"
      >
        {{ getToothNumber(31) }}
      </text>
    </g>
    <g id="g32" inkscape:label="g32">
      <linearGradient
        y2="927.49219"
        x2="437.2934"
        y1="927.49219"
        x1="387.43939"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-32-fill`"
      >
        <stop id="stop755" :style="getCustomColor(32)[0]" offset="0" />
        <stop id="stop757" :style="getCustomColor(32)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-32-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 398.6719,895.2201 c -12.9866,7.0248 -13.5741,49.6022 -7.5257,61.9611 6.0484,12.3589 54.6368,-8.5524 44.8495,-23.9677 -9.7874,-15.4153 -23.6048,-45.4142 -37.3238,-37.9934 z"
        class="st17"
        id="t32"
        inkscape:label="t32"
      />
      <text
        x="390"
        y="940"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('32')"
      >
        {{ getToothNumber(32) }}
      </text>
    </g>
    <g id="g33" inkscape:label="g33">
      <linearGradient
        y2="905.74951"
        x2="480.2728"
        y1="905.74951"
        x1="426.16791"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-33-fill`"
      >
        <stop id="stop767" :style="getCustomColor(33)[0]" offset="0" />
        <stop id="stop769" :style="getCustomColor(33)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-33-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 430.7592,880.0559 c -13.2423,15.6544 5.4668,52.4346 14.5355,55.9624 9.0687,3.5279 25.209,-8.6984 28.8517,-14.1388 3.6427,-5.4403 9.2794,-27.7304 3.9219,-33.6393 -5.3575,-5.9088 -36.1039,-21.4306 -47.3091,-8.1843 z"
        class="st18"
        id="t33"
        inkscape:label="t33"
      />
      <text
        x="435"
        y="915"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('33')"
      >
        {{ getToothNumber(33) }}
      </text>
    </g>
    <g id="g34" inkscape:label="g34">
      <linearGradient
        y2="859.04773"
        x2="514.22888"
        y1="859.04773"
        x1="459.20071"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-34-fill`"
      >
        <stop id="stop779" :style="getCustomColor(34)[0]" offset="0" />
        <stop id="stop781" :style="getCustomColor(34)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-34-fill);;fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 502.5191,836.7961 c 6.1392,5.4315 14.0961,21.3074 11.0309,30.4029 -3.0652,9.0955 -12.0329,20.938 -23.7123,20.938 -11.6794,0 -24.4852,-12.5036 -26.6375,-18.2401 -2.1523,-5.7365 -8.2028,-19.3244 0.759,-28.2861 19.064,-19.064 32.4207,-10.2461 38.5599,-4.8147 z"
        class="st19"
        id="t34"
        inkscape:label="t34"
      />
      <text
        x="470"
        y="870"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('34')"
      >
        {{ getToothNumber(34) }}
      </text>
    </g>
    <g id="g35" inkscape:label="g35">
      <linearGradient
        y2="802.31073"
        x2="535.80627"
        y1="802.31073"
        x1="478.78809"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-35-fill`"
      >
        <stop id="stop791" :style="getCustomColor(35)[0]" offset="0" />
        <stop id="stop793" :style="getCustomColor(35)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-35-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 483.7809,822.6717 c 4.9071,5.5792 11.467,6.6088 18.2715,7.703 8.7487,1.4069 17.9544,1.3879 25.1277,-4.9211 5.2218,-4.5927 6.2622,-11.0023 7.5598,-17.3271 1.8713,-9.1215 1.8456,-18.5414 -4.7779,-26.0721 -4.5414,-5.1636 -10.3712,-6.0754 -16.6175,-7.4025 -9.1803,-1.9504 -19.1927,-2.0541 -26.7817,4.6206 -6.321,5.5594 -6.9189,13.0645 -7.4695,20.8467 -0.5601,7.9183 -0.9691,16.1209 4.6876,22.5525 z"
        class="st20"
        id="t35"
        inkscape:label="t35"
      />
      <text
        x="490"
        y="815"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('35')"
      >
        {{ getToothNumber(35) }}
      </text>
    </g>
    <g id="g36" inkscape:label="g36">
      <linearGradient
        y2="728.97479"
        x2="564.77289"
        y1="728.97479"
        x1="486.30161"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-36-fill`"
      >
        <stop id="stop803" :style="getCustomColor(36)[0]" offset="0" />
        <stop id="stop805" :style="getCustomColor(36)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-36-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 490.9691,759.0976 c 7.6295,11.2856 19.3198,7.8795 27.0692,9.8955 7.7494,2.0161 19.1032,7.5496 30.9785,-2.3031 11.8754,-9.8527 10.3875,-19.3201 10.6359,-26.6851 0.2484,-7.365 4.3489,-14.0997 5.0443,-24.2823 0.7811,-11.4382 -4.4656,-27.7016 -15.5073,-28.4556 -7.9338,-0.5418 -10.6465,1.643 -17.3593,1.2827 -6.7127,-0.3604 -6.9205,-2.3948 -14.8543,-2.9366 -11.0417,-0.754 -17.8473,11.569 -21.5899,16.9932 -3.7427,5.4242 -6.9365,11.9432 -7.5544,20.9917 -0.618,9.0483 -4.4923,24.214 3.1373,35.4996 z"
        class="st21"
        id="t36"
        inkscape:label="t36"
      />
      <text
        x="505"
        y="740"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('36')"
      >
        {{ getToothNumber(36) }}
      </text>
    </g>
    <g id="g37" inkscape:label="g37">
      <linearGradient
        y2="637.7746"
        x2="582.67291"
        y1="637.7746"
        x1="504.63031"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-37-fill`"
      >
        <stop id="stop815" :style="getCustomColor(37)[0]" offset="0" />
        <stop id="stop817" :style="getCustomColor(37)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-37-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 508.6735,670.64 c 7.4047,12.0797 17.9892,7.1984 27.9705,7.967 9.9814,0.7687 20.3193,9.2388 32.2421,-1.2446 11.9228,-10.4834 9.1909,-23.0815 9.544,-30.9471 0.3531,-7.8655 3.3275,-10.3492 4.1649,-21.2226 0.9406,-12.2142 -6.7234,-28.5634 -17.6655,-29.406 -7.8622,-0.6055 -11.2156,-0.0901 -17.8691,-0.4977 -6.6535,-0.4076 -9.9797,-1.6469 -17.8419,-2.2524 -10.9421,-0.8427 -20.5748,8.3759 -21.5154,20.5901 -0.8374,10.8734 -0.0601,8.6721 -0.8041,18.3344 -0.7442,9.6624 -5.6302,26.5993 1.7745,38.6789 z"
        class="st22"
        id="t37"
        inkscape:label="t37"
      />
      <text
        x="525"
        y="650"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('37')"
      >
        {{ getToothNumber(37) }}
      </text>
    </g>
    <g id="g41" inkscape:label="g41">
      <linearGradient
        y2="942.22198"
        x2="338.83301"
        y1="942.22198"
        x1="293.8562"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-41-fill`"
      >
        <stop id="stop827" :style="getCustomColor(41)[0]" offset="0" />
        <stop id="stop829" :style="getCustomColor(41)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-41-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 322.7904,910.2396 c 9.8701,0.9946 17.8469,30.5365 15.6867,51.7698 -2.1602,21.2333 -47.9494,11.4954 -44.4277,-3.9805 3.5217,-15.4759 14.799,-49.1942 28.741,-47.7893 z"
        class="st23"
        id="t41"
        inkscape:label="t41"
      />
      <text
        x="300"
        y="960"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('41')"
      >
        {{ getToothNumber(41) }}
      </text>
    </g>
    <g id="g42" inkscape:label="g42">
      <linearGradient
        y2="927.49219"
        x2="292.56061"
        y1="927.49219"
        x1="242.7066"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-42-fill`"
      >
        <stop id="stop839" :style="getCustomColor(42)[0]" offset="0" />
        <stop id="stop841" :style="getCustomColor(42)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-42-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 281.3281,895.2201 c 12.9866,7.0248 13.5741,49.6022 7.5257,61.9611 -6.0484,12.3589 -54.6368,-8.5524 -44.8495,-23.9677 9.7874,-15.4153 23.6048,-45.4142 37.3238,-37.9934 z"
        class="st24"
        id="t42"
        inkscape:label="t42"
      />
      <text
        x="252"
        y="942"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('42')"
      >
        {{ getToothNumber(42) }}
      </text>
    </g>
    <g id="g43" inkscape:label="g43">
      <linearGradient
        y2="905.74951"
        x2="253.83211"
        y1="905.74951"
        x1="199.7272"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-43-fill`"
      >
        <stop id="stop851" :style="getCustomColor(43)[0]" offset="0" />
        <stop id="stop853" :style="getCustomColor(43)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-43-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 249.2408,880.0559 c 13.2423,15.6544 -5.4668,52.4346 -14.5355,55.9624 -9.0687,3.5279 -25.209,-8.6984 -28.8517,-14.1388 -3.6427,-5.4403 -9.2793,-27.7304 -3.9219,-33.6393 5.3575,-5.9088 36.1039,-21.4306 47.3091,-8.1843 z"
        class="st25"
        id="t43"
        inkscape:label="t43"
      />
      <text
        x="208"
        y="915"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('43')"
      >
        {{ getToothNumber(43) }}
      </text>
    </g>
    <g id="g44" inkscape:label="g44">
      <linearGradient
        y2="859.04773"
        x2="220.79939"
        y1="859.04773"
        x1="165.7711"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-44-fill`"
      >
        <stop id="stop863" :style="getCustomColor(44)[0]" offset="0" />
        <stop id="stop865" :style="getCustomColor(44)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-44-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 177.4809,836.7961 c -6.1392,5.4315 -14.0961,21.3074 -11.0309,30.4029 3.0652,9.0955 12.0329,20.938 23.7123,20.938 11.6794,0 24.4852,-12.5036 26.6375,-18.2401 2.1523,-5.7365 8.2028,-19.3244 -0.7589,-28.2861 -19.0641,-19.064 -32.4208,-10.2461 -38.56,-4.8147 z"
        class="st26"
        id="t44"
        inkscape:label="t44"
      />
      <text
        x="175"
        y="870"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('44')"
      >
        {{ getToothNumber(44) }}
      </text>
    </g>
    <g id="g45" inkscape:label="g45">
      <linearGradient
        y2="802.31073"
        x2="201.2119"
        y1="802.31073"
        x1="144.1937"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-45-fill`"
      >
        <stop id="stop875" :style="getCustomColor(45)[0]" offset="0" />
        <stop id="stop877" :style="getCustomColor(45)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-45-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 196.2191,822.6717 c -4.9071,5.5792 -11.467,6.6088 -18.2715,7.703 -8.7487,1.4069 -17.9544,1.3879 -25.1277,-4.9211 -5.2218,-4.5927 -6.2622,-11.0023 -7.5597,-17.3271 -1.8713,-9.1215 -1.8456,-18.5414 4.7779,-26.0721 4.5415,-5.1636 10.3711,-6.0754 16.6175,-7.4025 9.1803,-1.9504 19.1927,-2.0541 26.7817,4.6206 6.321,5.5594 6.9189,13.0645 7.4695,20.8467 0.56,7.9183 0.969,16.1209 -4.6877,22.5525 z"
        class="st27"
        id="t45"
        inkscape:label="t45"
      />
      <text
        x="155"
        y="815"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('45')"
      >
        {{ getToothNumber(45) }}
      </text>
    </g>
    <g id="g46" inkscape:label="g46">
      <linearGradient
        y2="728.97479"
        x2="193.69839"
        y1="728.97479"
        x1="115.227"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-46-fill`"
      >
        <stop id="stop887" :style="getCustomColor(46)[0]" offset="0" />
        <stop id="stop889" :style="getCustomColor(46)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-46-fill);;fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 192.1683,723.5979 c -0.6179,-9.0485 -3.8117,-15.5675 -7.5544,-20.9917 -3.7427,-5.4242 -10.5482,-17.7472 -21.59,-16.9932 -7.9338,0.5418 -8.1416,2.5762 -14.8543,2.9366 -6.7127,0.3604 -9.4255,-1.8245 -17.3593,-1.2827 -11.0417,0.754 -16.2885,17.0175 -15.5073,28.4556 0.6954,10.1826 4.796,16.9173 5.0443,24.2823 0.2484,7.3651 -1.2395,16.8324 10.6358,26.6851 11.8753,9.8527 23.2291,4.3192 30.9785,2.3031 7.7494,-2.0161 19.4397,1.3901 27.0692,-9.8955 7.6296,-11.2855 3.7554,-26.4512 3.1375,-35.4996 z"
        class="st28"
        id="t46"
        inkscape:label="t46"
      />
      <text
        x="135"
        y="740"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('46')"
      >
        {{ getToothNumber(46) }}
      </text>
    </g>
    <g id="g47" inkscape:label="g47">
      <linearGradient
        y2="637.7746"
        x2="175.36971"
        y1="637.7746"
        x1="97.327103"
        gradientUnits="userSpaceOnUse"
        :id="`t-${uniqueKey}-47-fill`"
      >
        <stop id="stop899" :style="getCustomColor(47)[0]" offset="0" />
        <stop id="stop901" :style="getCustomColor(47)[1]" offset="1" />
      </linearGradient>
      <path
        inkscape:connector-curvature="0"
        :style="`fill:url(#t-${uniqueKey}-47-fill);fill-opacity:0;stroke:#296fb3;stroke-miterlimit:10;stroke-width:4px`"
        d="m 173.1011,631.9612 c -0.7441,-9.6624 0.0332,-7.461 -0.8041,-18.3344 -0.9406,-12.2142 -10.5733,-21.4327 -21.5154,-20.5901 -7.8622,0.6055 -11.1884,1.8448 -17.8419,2.2524 -6.6536,0.4076 -10.0069,-0.1078 -17.8691,0.4977 -10.9421,0.8427 -18.606,17.1918 -17.6654,29.406 0.8373,10.8734 3.8117,13.3571 4.1648,21.2226 0.3531,7.8655 -2.3787,20.4637 9.544,30.9471 11.9227,10.4834 22.2607,2.0132 32.242,1.2446 9.9814,-0.7686 20.5659,4.1127 27.9705,-7.967 7.4047,-12.0797 2.5187,-29.0166 1.7746,-38.6789 z"
        class="st29"
        id="t47"
        inkscape:label="t47"
      />
      <text
        x="120"
        y="650"
        font-weight="bold"
        font-size="2rem"
        :style="textColor('47')"
      >
        {{ getToothNumber(47) }}
      </text>
    </g>
  </svg>
</template>

<script>
import internationalToothNumbers from "./international_tooth_numbers"

export default {
  name: "ToothPicker",
  props: [
    "value",
    "convention",
    "customColors",
    "labels",
    "dark",
    "interactive",
    "disabledTeeth"
  ],
  data() {
    return {
      uniqueKey: null
    }
  },
  computed: {
    teeth() {
      return Object.keys(internationalToothNumbers)
    },
    toothNamingConvention() {
      if (this.convention) {
        return this.convention
      }
      return "fdi"
    },
    fillColor() {
      if (this.dark) {
        return "fill:#ffffff"
      }
      return "fill:#000000"
    },
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    update: function () {
      this.teeth.forEach(this.empty)
      this.selected.forEach(this.fill)
      if (this.disabledTeeth && this.disabledTeeth.length) {
        this.disabledTeeth.forEach(this.disable)
      } else {
        this.teeth.forEach(this.enable)
      }
    },
    empty: function (value) {
      if (this.interactive) {
        this.$el.querySelector("#g" + value).onclick = () => {
          this.toothClicked(value)
        }
        this.$el.querySelector("#g" + value).classList.add("pointer")
      }
      this.$el.querySelector("#t" + value).classList.remove("full")
      this.$el.querySelector("#t" + value).classList.add("empty")
    },
    fill: function (value) {
      if (this.interactive) {
        this.$el.querySelector("#g" + value).onclick = () => {
          this.toothClicked(value)
        }
      }
      this.$el.querySelector("#t" + value).classList.remove("empty")
      this.$el.querySelector("#t" + value).classList.add("full")
    },
    disable: function (value) {
      this.$el.querySelector("#g" + value).classList.add("disabled")
    },
    enable: function (value) {
      this.$el.querySelector("#g" + value).classList.remove("disabled")
    },
    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.toothNamingConvention
      ].padStart(2, "0")
    },
    getCustomColor(toothNumber) {
      if (this.customColors && this.customColors[toothNumber]) {
        return [
          `stop-color:${this.customColors[toothNumber].start}`,
          `stop-color:${this.customColors[toothNumber].end}`
        ]
      }
      return ["stop-color:#30A4E0", "stop-color:#0071FF"]
    },
    toothClicked(tooth) {
      if (this.selected.includes(tooth)) {
        this.selected = this.selected.filter((item) => item !== tooth)
      } else {
        this.selected.push(tooth)
      }
    },
    textColor(tooth) {
      if (
        this.selected.includes(tooth) ||
        (this.distalExtension && this.prostheticCoverage.includes(tooth)) ||
        (this.distalExtension && this.distalExtensionCoverage.includes(tooth))
      ) {
        return "fill:#ffffff"
      } else {
        return "fill:#000000"
      }
    }
  },
  watch: {
    selected: function () {
      this.update()
    },
    distalExtensionCoverage: function () {
      setTimeout(this.update)
    }
  },
  mounted: function () {
    this.uniqueKey = Math.random().toString().split(".")[1]
    setTimeout(this.update, 100)
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.empty {
  fill-opacity: 0 !important;
}
.full {
  fill-opacity: 1 !important;
}
.disabled {
  user-select: none;
  pointer-events: none;
}
</style>
