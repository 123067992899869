<template>
  <v-container
    fluid
    class="d-flex justify-center align-center flex-column"
    fill-height
  >
    <v-img
      v-if="settings && !settings.logo_url"
      style="flex-grow: 0"
      contain
      width="300"
      :src="require('@/assets/nexus-connect-logo.svg')"
    />
    <v-img
      v-if="settings && settings.logo_url"
      style="flex-grow: 0"
      contain
      width="300"
      :src="settings.logo_url"
    />
    <!-- <v-img
      src="@/assets/nexus-connect-logo.svg"
      width="360"
      contain
      style="flex-grow: 0"
    /> -->
    <span
      class="title-font mt-4"
      style="font-size: 17px; letter-spacing: 0.25ch"
    >
      {{ settings.tenant_name }}
    </span>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"

export default {
  name: "ConnectBranding",

  computed: {
    ...mapGetters(["settings"])
  }
}
</script>
