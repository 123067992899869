<template>
  <component
    :is="component"
    @switch="handleSwitch"
    @next="$emit('next')"
    @step="(step) => $emit('step', step)"
  />
</template>

<script>
import { mapGetters } from "vuex"
import PostAlignment from "./ScanAnalysis/PostAlignment.vue"
import PreAlignment from "./ScanAnalysis/PreAlignment.vue"
export default {
  name: "ScanAnalysis",
  components: {
    PreAlignment,
    PostAlignment
  },
  data() {
    return {
      component: PreAlignment
    }
  },
  computed: {
    ...mapGetters(["validatedScansResponse"])
  },
  methods: {
    handleSwitch() {
      this.component =
        this.component === PreAlignment ? PostAlignment : PreAlignment
    }
  },
  beforeCreate() {
    if (this.validatedScansResponse) this.component = PostAlignment
  }
}
</script>
