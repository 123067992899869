<template>
  <v-app-bar app clipped-left clipped-right color="white">
    <v-spacer />
    <v-toolbar-title @click="navigateHome()">
      <v-img
        v-if="settings && !settings.logo_url"
        @click="navigateHome"
        contain
        height="50"
        style="cursor: pointer"
        :src="require('@/assets/nexus-connect-logo.svg')"
      />
      <v-img
        v-if="settings && settings.logo_url"
        @click="navigateHome"
        contain
        height="50"
        style="cursor: pointer"
        :src="settings.logo_url"
      />
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      elevation="0"
      class="rounded-10"
      @click="logOut"
      v-if="settings?.manage_clients"
    >
      <v-icon class="mr-2"> mdi-logout </v-icon>
      Log out
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "AppBar",

  computed: {
    ...mapGetters(["settings"])
  },

  methods: {
    navigateHome() {
      this.$router.push("/").catch(() => {})
    },

    logOut() {
      const slug = this.$route.path.replace("/", "")
      window.location = `/logout/?slug=${slug}`
    }
  }
}
</script>
