<template>
  <v-card class="pa-2 rounded-10">
    <v-card-title class="title-font mb-1" style="font-weight: 600">
      ORDER SUBMITTED
    </v-card-title>

    <v-card-text class="text-body-1 black--text ml-3">
      <v-row align="center">
        <v-col cols="3">
          <v-icon color="success" size="128">mdi-check-circle</v-icon>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <strong> Order Number: </strong> {{ orderDetails.order_number }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Your order has been submitted to {{ settings.tenant_name }}.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-primary" @click="newSession">
        Create a New Order
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  name: "CompleteCard",
  computed: {
    ...mapGetters(["orderDetails", "settings"])
  },
  methods: {
    ...mapActions(["newSession"])
  }
}
</script>
