import { axiosClient } from "@/plugins/axios"

class Tenants {
  constructor() {
    this.tenantSlug = null
  }

  setTenantSlug(slug) {
    this.tenantSlug = slug
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchTenantInformation() {
    const response = await axiosClient.get(
      `/tenants/ordering/${this.tenantSlug}`
    )
    return response.data
  }
}

class ImplantPlatforms {
  constructor() {
    this.tenantSlug = null
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchImplantPlatforms() {
    const response = await axiosClient.get(
      `/implant_platforms/${this.tenantUid}`
    )
    return response.data
  }
}

export default class GatewayAPI {
  constructor() {
    this.tenants = new Tenants()
    this.implantPlatforms = new ImplantPlatforms()
  }

  setTenantSlug(slug) {
    this.tenants.setTenantSlug(slug)
  }

  setTenantUid(uid) {
    this.tenants.setTenantUid(uid)
    this.implantPlatforms.setTenantUid(uid)
  }
}
