<template>
  <v-badge overlap color="red">
    <template slot="badge">
      <v-icon @click="deleteFile()">mdi-close</v-icon>
    </template>
    <v-card
      class="rounded-10"
      color="off"
      width="180"
      height="180"
      v-if="fileType === 'image' && url"
    >
      <v-img :src="url" max-width="180" height="65%">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="primary"
              class="my-5"
            >
              Loading
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-text :class="[textColour, 'font-weight-medium']">
        <small>{{ file.file_name }}</small>
      </v-card-text>
    </v-card>
    <v-card
      class="rounded-10"
      color="off"
      width="180"
      height="180"
      v-if="fileType === 'model' && url"
    >
      <!-- background-color="#1E1E1E" -->
      <ModelViewer
        class="ma-0 pa-0"
        :file-path="url"
        :width="180"
        :height="117"
      />
      <v-card-text :class="[textColour, 'font-weight-medium']">
        <small>{{ file.file_name }}</small>
      </v-card-text>
    </v-card>
    <v-card
      class="rounded-10"
      color="off"
      width="180"
      height="180"
      v-if="fileType === 'unknown'"
    >
      <v-icon size="90" class="pa-5">mdi-file</v-icon>
      <v-card-text :class="[textColour, 'font-weight-medium']">
        <small>{{ file.file_name }}</small>
      </v-card-text>
    </v-card>
  </v-badge>
</template>

<script>
import { mapGetters } from "vuex"
import ModelViewer from "./ModelViewer.vue"
import { getTextColour } from "@/utils"
import clientOptions from "@/plugins/axios/client_options"

export default {
  name: "UploadCard",
  props: ["file"],
  components: {
    ModelViewer
  },
  watch: {
    file(value) {
      this.url = null
      if (value) {
        this.fetchURL()
      }
    }
  },
  data() {
    return {
      url: null,
      deleted: false
    }
  },
  computed: {
    ...mapGetters(["settings"]),
    textColour() {
      return `${getTextColour(this.settings.colours.off)}--text`
    },
    fileExtention() {
      const parts = this.file.file_name.split(".")
      return parts[parts.length - 1].toLowerCase()
    },
    fileType() {
      switch (this.fileExtention) {
        case "jpg":
        case "jpeg":
        case "png":
          return "image"
        case "stl":
        case "ply":
          return "model"
        default:
          return "unknown"
      }
    }
  },
  methods: {
    fetchURL() {
      this.$axios
        .post(`${clientOptions.oftURL}/${this.file.oft_file_uid}/download`)
        .then((response) => {
          this.url = response.data.oneTimeDownloadLink
        })
    },
    deleteFile() {
      this.$emit("delete", true)
    }
  },
  mounted() {
    this.fetchURL()
  }
}
</script>

<style scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 999;
}
</style>
<style>
.v-progress-circular__overlay {
  transition: none !important;
}
</style>
