import Vue from "vue"
import VueRouter from "vue-router"
import HomePage from "@/components/HomePage"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: HomePage,
    name: "homeWithoutSlug"
  },
  {
    path: "/callback",
    component: HomePage,
    name: "callback"
  },
  {
    path: "/:slug",
    component: HomePage,
    name: "homeWithSlug"
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
