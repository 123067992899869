<template>
  <v-card-text class="black--text">
    <p class="mt-0">
      Your Scan Gauge Scans have failed alignment. <br />
      This indicates a repeatability issue, the scans don't match.
    </p>
    <p>
      To resolve, rearrance the gauges on the arch by following the steps below,
      then rescan following the same steps.
    </p>
    <v-card color="off" class="pa-1" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        1. Close Gaps
      </v-card-title>
      <v-card-text>
        Ensure there are no large gaps between gauges.
      </v-card-text>
      <div class="d-flex justify-center pb-3">
        <v-img
          src="@/assets/suggestions/close-gaps.svg"
          width="150"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
    <v-card color="off" class="pa-1 mt-2" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        2. Horizontal Overlap
      </v-card-title>
      <v-card-text>
        Ensure sufficient overlap of Scan Gauges so the scanner's field of view
        can see more than one Gauge at any time.
      </v-card-text>
      <div class="d-flex justify-center pb-3">
        <v-img
          src="@/assets/suggestions/horizontal-overlap.svg"
          width="220"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
  </v-card-text>
</template>

<script>
export default {
  name: "SuggestionPage1"
}
</script>
