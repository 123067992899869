<template>
  <v-card class="pa-2 rounded-10">
    <v-card-title class="title-font pb-0" style="font-weight: 600">
      SUGGESTED SOLUTIONS
    </v-card-title>
    <v-carousel
      v-model="currentStepIndex"
      height="100%"
      flat
      class="pa-0 pb-10"
      hide-delimiter-background
      show-arrows-on-hover
      light
    >
      <v-carousel-item
        v-for="(item, index) in items"
        :key="`suggestions${index}`"
        class="pa-0"
      >
        <component :is="item" />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import SuggestionPage1 from "./SuggestedSolutions/SuggestionPage1.vue"
import SuggestionPage2 from "./SuggestedSolutions/SuggestionPage2.vue"
import SuggestionPage3 from "./SuggestedSolutions/SuggestionPage3.vue"
import SuggestionPage4 from "./SuggestedSolutions/SuggestionPage4.vue"
export default {
  name: "SuggestedSolutions",
  components: {
    SuggestionPage1,
    SuggestionPage2,
    SuggestionPage3,
    SuggestionPage4
  },
  data() {
    return {
      currentStepIndex: 0,
      items: [
        SuggestionPage1,
        SuggestionPage2,
        SuggestionPage3,
        SuggestionPage4
      ]
    }
  }
}
</script>
