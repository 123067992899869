<template>
  <div>
    <span class="primary--text" v-if="!selectGaugesResponse">
      Select the <strong> top facet </strong> of each Scan Gauge in each scan to
      prepare.
    </span>
    <span class="primary--text" v-if="selectGaugesResponse">
      Confirm that the Scan Gauges have been selected correctly.
    </span>
    <v-card flat class="d-flex">
      <template v-if="!selectGaugesResponse">
        <v-card-text>
          <strong>Computer</strong>
          <ul>
            <li>Left-click to select.</li>
            <li>Click any facet again to unselect.</li>
            <li>Rotate scan by holding left-click and dragging.</li>
            <li>Use mouse scroll-wheel to zoom.</li>
          </ul>
        </v-card-text>
        <v-card-text>
          <strong>Touch Devices</strong>
          <ul>
            <li>Tap to select.</li>
            <li>Tap again to unselect.</li>
            <li>Hold and drag to rotate scan.</li>
            <li>Pinch to zoom.</li>
          </ul>
        </v-card-text>
      </template>
      <template v-if="selectGaugesResponse">
        <v-card-text>
          <strong>Ready to continue?</strong>
          <ul>
            <li>Click <strong>BEGIN ALIGNMENT</strong>.</li>
          </ul>
        </v-card-text>
        <v-card-text>
          <strong>Made a mistake?</strong>
          <ul>
            <li>Click <strong>RE-SELECT GAUGES</strong> to try again.</li>
          </ul>
        </v-card-text>
      </template>
    </v-card>
    <div class="d-flex">
      <template v-if="!selectGaugesResponse">
        <v-btn
          class="elevation-0 white--text text-body-2 font-weight-medium btn-primary mr-3"
          @click="select"
          :disabled="loading"
        >
          Confirm Selections
        </v-btn>
        <v-btn
          class="elevation-0 rounded-10 text-body-2 font-weight-medium"
          @click="clearSelections"
          :disabled="loading"
        >
          Clear All Selections
        </v-btn>
      </template>
      <template v-if="selectGaugesResponse">
        <v-btn
          class="elevation-0 white--text text-body-2 font-weight-medium rounded-10 elevation-0 warning mr-3"
          @click="align"
          :disabled="loading"
        >
          Begin Alignment
        </v-btn>
        <v-btn
          class="elevation-0 rounded-10 text-body-2 font-weight-medium"
          @click="reselect"
          :disabled="loading"
        >
          Re-Select Gauges
        </v-btn>
      </template>
      <div class="flex-grow-1 ml-5 mr-1" v-if="loading">
        <span>Processing...</span>
        <v-progress-linear indeterminate />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import apiClient from "@/lib/ApiClient"

export default {
  name: "PreAlignment",

  scan1View: null,
  scan2View: null,

  data() {
    return {
      loading: false,
      selectGaugesResponse: null
    }
  },

  computed: {
    ...mapGetters([
      "accessToken",
      "retrying",
      "orderDetails",
      "addScansResponse",
      "faceCount",
      "validateScansResponse",
      "validationSuccess"
    ])
  },

  methods: {
    ...mapActions([
      "setValidateScansResponse",
      "setPageState",
      "setTransition",
      "setRetrying",
      "setFaceCount",
      "setErrorMessage"
    ]),

    async select() {
      this.scan1View = NCFrontend.get("scan1View")
      this.scan2View = NCFrontend.get("scan2View")

      const scan1SelectedPlaneIndices =
        this.scan1View.visualisation.selectedPlaneIndices
      const scan2SelectedPlaneIndices =
        this.scan2View.visualisation.selectedPlaneIndices
      if (
        scan1SelectedPlaneIndices.length === 0 ||
        scan2SelectedPlaneIndices.length === 0
      ) {
        this.setErrorMessage("No Scan Gauges have been selected.")
        return
      }
      if (
        scan1SelectedPlaneIndices.length !== scan2SelectedPlaneIndices.length
      ) {
        this.setErrorMessage("Mismatching number of Scan Gauges selected.")
        return
      }
      if (
        this.faceCount !== null &&
        scan1SelectedPlaneIndices.length !== this.faceCount
      ) {
        this.setErrorMessage(
          `Selection Mismatch: Must select ${this.faceCount} Scan Gauges`
        )
        return
      }

      try {
        this.loading = true
        this.scan1View.visualisation.stopSelection()
        this.scan2View.visualisation.stopSelection()
        const selectGaugesResponse =
          await NCFrontend.ApiClient.sessionSelectGauges(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            `Bearer ${this.accessToken}`,
            {
              sessionId: this.orderDetails.nc_session_id,
              scan1Id: this.addScansResponse.scan1.id,
              scan2Id: this.addScansResponse.scan2.id,
              scan1SelectedPlaneIndices,
              scan2SelectedPlaneIndices
            }
          )

        await Promise.all([
          this.scan1View.visualisation.showSelectedGauges(
            selectGaugesResponse.scan1.scanGauges
          ),
          this.scan2View.visualisation.showSelectedGauges(
            selectGaugesResponse.scan2.scanGauges
          )
        ])
        this.selectGaugesResponse = selectGaugesResponse
      } catch (err) {
        this.setErrorMessage("An internal error occurred.")
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    async align() {
      try {
        this.loading = true
        this.setValidateScansResponse(
          await NCFrontend.ApiClient.sessionValidateScans(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            `Bearer ${this.accessToken}`,
            {
              sessionId: this.orderDetails.nc_session_id,
              scan1Id: this.addScansResponse.scan1.id,
              scan2Id: this.addScansResponse.scan2.id,
              retrying: this.retrying
            }
          )
        )
        this.setTransition(true)
        if (!this.validationSuccess)
          this.setPageState([
            { cols: 3, component: "SuggestedSolutions" },
            { cols: 5, component: "ValidatedDiv" }
          ])
        else this.setPageState([{ cols: 7, component: "ValidatedDiv" }])

        if (this.faceCount === null)
          this.setFaceCount(
            NCFrontend.get("scan1View").visualisation.selectedPlaneIndices
              .length
          )

        this.setRetrying(false)

        this.$emit("switch")
      } catch (err) {
        this.setErrorMessage("An internal error occurred.")
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    reselect() {
      NCFrontend.get("scan1View").visualisation.startSelection()
      NCFrontend.get("scan2View").visualisation.startSelection()
      this.selectGaugesResponse = null
    },

    clearSelections() {
      NCFrontend.get("scan1View").visualisation.clearSelections()
      NCFrontend.get("scan2View").visualisation.clearSelections()
    }
  }
}
</script>
