<template>
  <div>
    <span class="primary--text d-block mb-4">
      Upload additional Scans and Images.
    </span>
    <v-card flat>
      <v-card
        :color="dragOver ? 'card' : 'off'"
        outlined
        :light="dropBoxTextColor === 'black'"
        :dark="dropBoxTextColor === 'white'"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragOver = true"
        @dragenter.prevent="dragOver = true"
        @dragleave.prevent="dragOver = false"
        style="cursor: pointer"
        :class="{ pulsing: dragOver, 'rounded-10': true, 'mb-5': true }"
      >
        <v-card-text @click="addFile()">
          <v-row>
            <v-col class="text-center">
              <v-icon size="60" class="mt-5"> mdi-cloud-upload </v-icon>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col class="text-center">
              <h3 v-if="!smallScreen" class="title-font">
                Drop your file(s) or folder here, or click to select them.
              </h3>
              <h3 v-if="smallScreen">Click to select your file(s).</h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="loading"
        max-width="600"
        persistent
        content-class="rounded-10"
      >
        <v-card color="off" class="rounded-10" v-if="loading">
          <v-card-text class="text-center pt-5">
            <v-row align="center" justify="center">
              <v-col cols="12" class="pa-5">
                <v-progress-circular
                  :rotate="-90"
                  :width="8"
                  :size="200"
                  :value="totalUploadProgress"
                  color="primary"
                >
                  <span class="primary--text">
                    Uploaded Files
                    {{ uploadsCompleted }} of {{ files.length }}
                    <br />
                    {{ totalUploadProgress }}%
                  </span>
                </v-progress-circular>
              </v-col>
            </v-row>
            <p :class="['mt-5', textColour]">
              Please wait for your uploads to finish before continuing.
            </p>
            <v-progress-linear
              stream
              buffer-value="0"
              :value="totalUploadProgress"
              color="success"
              class="rounded-lg pb-3"
              v-if="loading"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="elevation-0" v-if="existingUploads.length > 0">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col
              cols="12"
              lg="6"
              xl="4"
              v-for="(upload, index) in existingUploads"
              :key="`uploaded_${index}`"
            >
              <UploadCard :file="upload" @delete="deleteUpload(upload)" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-btn @click="$emit('next')" class="btn-primary"> Continue </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { getTextColour } from "@/utils"
import client from "@/lib/ApiClient"
import clientOptions from "@/plugins/axios/client_options"
import UploadCard from "./UploadFiles/UploadCard.vue"

export default {
  name: "UploadFiles",

  components: {
    UploadCard
  },
  data() {
    return {
      inputDOMElement: null,
      uploadsCompleted: 0,
      dragOver: false,
      files: [],
      loading: false
    }
  },

  computed: {
    ...mapGetters(["orderUid", "additionalFiles", "settings", "country"]),

    textColour() {
      return `${getTextColour(this.settings.colours.off)}--text`
    },

    dropBoxTextColor() {
      if (this.dragOver) return getTextColour(this.settings.colours.card)
      else return getTextColour(this.settings.colours.off)
    },

    existingUploads() {
      return this.additionalFiles || []
    },

    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      )
    },

    totalUploadProgress() {
      return Math.round((100 * this.uploadsCompleted) / this.files.length)
    }
  },

  methods: {
    ...mapActions(["setAdditionalFiles"]),

    async onDrop(event) {
      await Promise.all(
        [...event.dataTransfer.items].map(async (item) => {
          const wkItem = item.webkitGetAsEntry()
          if (wkItem.isFile) {
            const fileItem = await new Promise((resolve) =>
              wkItem.file(resolve)
            )
            this.files.push(fileItem)
          } else if (wkItem.isDirectory) {
            await this.traverseDirectory(wkItem)
          }
        })
      )
      this.dragOver = false
      this.uploadFiles()
    },

    async traverseDirectory(wkItem) {
      const entries = await new Promise((resolve) =>
        wkItem.createReader().readEntries(resolve)
      )
      await Promise.all(
        entries.map(async (entry) => {
          if (entry.isFile) {
            const fileItem = await new Promise((resolve) => entry.file(resolve))
            this.files.push(fileItem)
          } else if (entry.isDirectory) {
            await this.traverseDirectory(entry)
          }
        })
      )
    },

    addFile() {
      if (!this.inputDOMElement) {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = true
        this.inputDOMElement.addEventListener("change", (event) => {
          const array = [...event.target.files]
          array.forEach((entry) => {
            this.files.push(entry)
          })
          this.uploadFiles()
        })
      }
      this.inputDOMElement.click()
    },

    async uploadFiles() {
      this.loading = true
      try {
        const oftResponse = await this.oftAddFiles()

        const payload = []
        const promises = []

        for (const [index, f] of oftResponse.data.entries()) {
          promises.push(
            this.uploadToGoogleStorage(this.files[index], f.oneTimeUploadLink)
          )

          payload.push({
            file_name: f.fileName,
            file_type: this.fileType(this.files[index]),
            oft_file_uid: f.uid
          })
        }

        promises.push(client.instance.orders.addFiles(this.orderUid, payload))
        const results = await Promise.all(promises)
        const addedFiles = results[results.length - 1]
        this.setAdditionalFiles([...this.additionalFiles, ...addedFiles.data])
      } finally {
        this.files = []
        this.uploadsCompleted = 0
        this.loading = false
      }
    },

    async oftAddFiles() {
      const payload = this.files.map((file) => {
        return {
          fileName: file.name,
          fileReferences: [
            {
              entityType: "NCLABS_ORDER",
              entityIdentification: this.orderUid
            }
          ]
        }
      })
      try {
        const response = await this.$axios.post(
          `${clientOptions.oftURL}/upload/${this.country}`,
          payload
        )
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async uploadToGoogleStorage(file, uploadLink) {
      try {
        const response = await fetch(uploadLink, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream"
          },
          body: file
        })
        this.uploadsCompleted++
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async deleteUpload(file) {
      const uploads = [...this.additionalFiles]
      const index = uploads.indexOf(file)
      if (index < 0) {
        return
      }
      uploads.splice(index, 1)
      this.setAdditionalFiles(uploads)
      client.instance.orders.deleteFile(this.orderUid, file.uid)
    },

    fileType(file) {
      if (!file?.filename) {
        return
      }
      const fileExt = file.filename.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "jpg":
        case "jpeg":
        case "png":
          return "photo"
        case "ply":
        case "stl":
          return "scan"
        default:
          return "other"
      }
    }
  }
}
</script>
