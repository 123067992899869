<template>
  <div>
    <span class="text-caption grey--text text--lighten-1">
      This application is not intended to be used as a medical device by the
      manufacturer.
    </span>
    <v-container class="d-flex align-center pa-0">
      <div class="my-3 ml-3 mr-13">
        <v-fade-transition>
          <div
            v-if="!transition && validationSuccess"
            class="d-flex flex-column align-center"
          >
            <v-progress-circular
              color="success"
              size="96"
              :rotate="270"
              :value="progress"
              class="progress-circular-transition"
            >
              <span class="success--text title-font" style="font-size: x-large">
                PASS
              </span>
            </v-progress-circular>
          </div>
        </v-fade-transition>
        <v-fade-transition>
          <div
            v-if="!transition && !validationSuccess"
            class="d-flex flex-column align-center"
          >
            <v-progress-circular
              color="error"
              size="96"
              :rotate="270"
              :value="progress"
              class="progress-circular-transition"
            >
              <span class="error--text title-font" style="font-size: x-large">
                FAIL
              </span>
            </v-progress-circular>
          </div>
        </v-fade-transition>
      </div>
      <div>
        <v-row align="center" class="mt-0">
          <v-btn
            @click="onScanColorsButtonClick"
            :color="validationSuccess ? 'success' : 'error'"
            class="text-body-2 elevation-0 mt-5 mr-8 rounded-10"
          >
            {{ toggleScanColorsButtonLabel }}
          </v-btn>
          <v-checkbox
            v-model="showScanTissue"
            label="Show Scan Tissue"
            hide-details
          />
        </v-row>
        <v-row style="height: 90px">
          <v-col v-if="showHeatmapColors">
            <HeatmapScale class="ml-5" />
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div
      v-if="uploadedScans.length === 3 && validationSuccess === false"
      class="mt-5 mb-n2"
    >
      &nbsp;<v-icon color="error" class="mr-1">mdi-alert-circle</v-icon>
      Maximum retry count reached.
    </div>
    <v-row class="mt-0">
      <v-col>
        <v-btn
          v-if="validationSuccess"
          class="btn-primary mr-3"
          @click="$emit('next')"
        >
          Continue
        </v-btn>
        <template v-if="!validationSuccess">
          <v-btn
            :disabled="!retryPermitted"
            @click="onRetry"
            class="my-3 mx-2 btn-primary"
          >
            Retry With New Scans
          </v-btn>
          <v-btn class="elevation-0 rounded-10" @click="restart">
            Restart Session
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.progress-circular-transition .v-progress-circular__overlay {
  transition: all 0.6s ease-in-out !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import HeatmapScale from "./PostAlignment/HeatmapScale.vue"
import heatmapImg from "@/assets/image-heatmap-scale-light.svg"

export default {
  name: "PostAlignment",
  components: {
    HeatmapScale
  },

  data() {
    return {
      heatmapImg,
      progress: 0,
      showHeatmapColors: false,
      showScanTissue: true
    }
  },

  mounted() {
    setTimeout(() => (this.progress = 100), 500)
  },

  watch: {
    showScanTissue(value) {
      NCFrontend.get("scanValidationView").visualisation.setScanTissueVisible(
        value
      )
    }
  },

  computed: {
    ...mapGetters([
      "validateScansResponse",
      "validationSuccess",
      "uploadedScans",
      "transition",
      "retryCount"
    ]),

    toggleScanColorsButtonLabel() {
      if (!this.showHeatmapColors) {
        return "Show Heatmap Colors"
      }
      return "Show Score Colors"
    },

    retryPermitted() {
      return this.uploadedScans.length < 3
    }
  },
  methods: {
    ...mapActions([
      "setValidateScansResponse",
      "setRetryCount",
      "setPageState",
      "setRetrying",
      "restartSession"
    ]),

    onScanColorsButtonClick() {
      if (!this.showHeatmapColors) {
        NCFrontend.get("scanValidationView").visualisation.showHeatmapColors()
        this.showHeatmapColors = true
      } else {
        NCFrontend.get("scanValidationView").visualisation.showScoreColors()
        this.showHeatmapColors = false
      }
    },

    restart() {
      NCFrontend.get("scan1View").clearVisualisation()
      NCFrontend.get("scan2View").clearVisualisation()
      NCFrontend.remove(
        NCFrontend.get("scan1View"),
        NCFrontend.get("scan2View")
      )
      this.restartSession()
      this.$emit("switch")
      this.$emit("step", "UploadScans")
    },

    onRetry() {
      NCFrontend.get("scan1View").clearVisualisation()
      NCFrontend.get("scan2View").clearVisualisation()
      NCFrontend.get("scanValidationView").clearVisualisation()
      this.setValidateScansResponse(null)
      this.setRetryCount(this.retryCount + 1)
      this.setRetrying(true)

      this.setPageState([{ cols: 7, component: "ConnectBranding" }])
      this.$emit("switch")

      this.$emit("step", "UploadScans")
    }
  }
}
</script>
