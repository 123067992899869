import { axiosClient } from "@/plugins/axios"

export class Users {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchMe() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/users/me/`
    )
    return response.data
  }
}

export class Orders {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  /* Questions */

  async getQuestions() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/form/public/`
    )
    return response.data
  }

  /* Files */

  uploadFile(sfcInstance, formData) {
    return axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/public/${sfcInstance.orderUid}/files/`,
      formData,
      {
        timeout: 86400000,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          sfcInstance.uploadProgress =
            (progressEvent.loaded * 100) / progressEvent.total
        }
      }
    )
  }

  async addFiles(orderUid, payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/public/${orderUid}/files/`,
      payload
    )
    return response
  }

  async deleteFile(orderUid, fileUid) {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/public/${orderUid}/files/${fileUid}/`
    )
    return response.data
  }

  /* Orders */

  async createOrder(form) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/public/`,
      form
    )
    return response.data
  }

  async updateOrder(orderUid, form) {
    const response = await axiosClient.put(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/public/${orderUid}/`,
      form
    )
    return response.data
  }

  async submitOrder(orderUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/public/${orderUid}/submit/`
    )
    return response.data
  }
}

export class Tenants {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchTenantSettings() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/settings/public/`
    )
    return response.data
  }

  async fetchTenantKits() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/gauge_kits/`
    )
    return response.data
  }

  async authenticateKitSerialNumber(kitSerialNumber) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/gauge_kits/authenticate/`,
      { kit_serial_number: kitSerialNumber }
    )
    return response.data
  }
}

export class Clients {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async introspect() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/clients/introspect/`
    )
    return response.data
  }
}

export default class InstanceAPI {
  constructor() {
    this.users = new Users()
    this.orders = new Orders()
    this.tenants = new Tenants()
    this.clients = new Clients()
  }

  setTenantUid(uid) {
    this.users.setTenantUid(uid)
    this.orders.setTenantUid(uid)
    this.tenants.setTenantUid(uid)
    this.clients.setTenantUid(uid)
  }

  setInstanceUrl(url) {
    this.users.setInstanceUrl(url)
    this.orders.setInstanceUrl(url)
    this.tenants.setInstanceUrl(url)
    this.clients.setInstanceUrl(url)
  }
}
