<template>
  <svg
    width="62"
    height="42"
    viewBox="0 0 62 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.9209 13.3145V20.0483C30.9209 20.4643 30.8421 20.862 30.6984 21.2272C30.2272 22.4281 29.0579 23.2785 27.6897 23.2785H24.6443C24.477 23.2785 24.3115 23.268 24.1494 23.247C22.3469 23.0202 20.9271 21.568 20.7492 19.7514C20.7361 19.6244 20.73 19.4956 20.73 19.3651V13.3145C20.73 10.5003 23.0117 8.21948 25.825 8.21948C27.2325 8.21948 28.5061 8.78968 29.4284 9.71199C30.3507 10.6334 30.9209 11.9078 30.9209 13.3145Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.7298 13.1427V19.0033C20.7298 19.2625 20.6939 19.513 20.6273 19.7513C20.3015 20.911 19.2364 21.7606 17.9725 21.7606H16.332C15.3212 21.7606 14.4559 21.1396 14.0968 20.2584C13.982 19.9773 13.9189 19.6698 13.9189 19.3475V13.1427C13.9189 11.2613 15.4439 9.7373 17.3244 9.7373C18.2651 9.7373 19.1164 10.1183 19.7322 10.734C20.3488 11.3507 20.7298 12.202 20.7298 13.1427Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.93781 12.8073V19.7487C4.93781 19.768 4.93605 19.7864 4.93342 19.8047C4.89314 20.0789 4.55681 20.3005 4.12238 20.3504C4.06192 20.3574 3.99974 20.3609 3.93668 20.3609C3.93668 20.3609 2.79102 20.0868 2.79102 19.7487V13.2286C2.79102 12.8905 3.23948 12.1951 3.79302 12.1951H3.93668C4.48936 12.1951 4.93781 12.4692 4.93781 12.8073Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.74538 14.1649V18.6398C2.74538 19.0585 2.36351 19.5385 1.89315 19.5385C1.89315 19.5385 1.04004 19.0585 1.04004 18.6398V14.1649C1.04004 13.7454 1.42193 13.4055 1.89315 13.4055C2.1279 13.4055 2.34161 13.4905 2.49577 13.628C2.64992 13.7655 2.74538 13.9556 2.74538 14.1649Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9192 12.8756V18.843C13.9192 19.4123 13.6906 20.0912 13.3166 20.7025C13.1309 21.0073 12.9085 21.2955 12.6606 21.5434C12.566 21.6388 12.467 21.7282 12.3654 21.8105C12.3313 21.8385 12.2953 21.8657 12.2603 21.892C11.6621 22.3378 10.7897 22.2712 10.2537 21.7615C9.94711 21.4707 9.5836 21.0993 9.2569 20.6981C8.75853 20.0885 8.34424 19.4115 8.34424 18.843V12.8756C8.34424 11.4506 9.5915 10.2961 11.1313 10.2961C11.9012 10.2961 12.5984 10.5852 13.1029 11.0512C13.6074 11.518 13.9192 12.1635 13.9192 12.8756Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.1118 13.3145V19.3651C41.1118 19.4956 41.1057 19.6244 41.0925 19.7514C40.9147 21.5688 39.4949 23.0202 37.6924 23.2479C37.5303 23.268 37.3648 23.2785 37.1975 23.2785H34.1511C32.783 23.2785 31.6137 22.4281 31.1434 21.2272C30.9997 20.862 30.9209 20.4643 30.9209 20.0483V13.3145C30.9209 11.9078 31.4911 10.6334 32.4134 9.71199C33.3348 8.78968 34.6092 8.21948 36.0159 8.21948C38.8301 8.21948 41.1118 10.5003 41.1118 13.3145Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.9227 13.1427V19.3475C47.9227 19.6698 47.8596 19.9773 47.7449 20.2584C47.3858 21.1396 46.5195 21.7606 45.5087 21.7606H43.8691C42.6052 21.7606 41.5401 20.911 41.2143 19.7513C41.1477 19.513 41.1118 19.2625 41.1118 19.0033V13.1427C41.1118 12.202 41.4928 11.3507 42.1086 10.734C42.7252 10.1183 43.5765 9.7373 44.5173 9.7373C46.3978 9.7373 47.9227 11.2613 47.9227 13.1427Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M56.903 13.0542V19.9588C56.903 20.3476 56.4861 20.7006 56.056 20.9616C55.5979 21.2384 55.1259 21.4118 55.1259 21.4118C54.3664 21.4118 53.7288 20.9485 53.5484 20.3214C53.5151 20.2049 53.4976 20.084 53.4976 19.9588V13.0542C53.4976 12.251 54.2263 11.6011 55.1259 11.6011H55.2756C56.1743 11.6011 56.903 12.251 56.903 13.0542Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.0496 13.2286V19.7487C59.0496 20.0868 57.9049 20.3609 57.9049 20.3609C57.8418 20.3609 57.7796 20.3574 57.7192 20.3504C57.2839 20.3005 56.9484 20.0789 56.9072 19.8047C56.9046 19.7864 56.9028 19.768 56.9028 19.7487V12.8073C56.9028 12.4692 57.3513 12.1951 57.9049 12.1951H58.0485C58.6012 12.1951 59.0496 12.8905 59.0496 13.2286Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.9484 19.5384C59.9484 19.5384 60.8011 19.0588 60.8011 18.6398V14.1644C60.8011 13.7454 60.4193 13.4058 59.9484 13.4058C59.4775 13.4058 59.0957 13.7454 59.0957 14.1644V18.6398C59.0957 19.0588 59.4775 19.5384 59.9484 19.5384Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.4974 12.8756V18.843C53.4974 19.4106 53.0831 20.0885 52.5847 20.6973C52.258 21.0984 51.8928 21.4698 51.5871 21.7615C51.051 22.2712 50.1795 22.3378 49.5804 21.892C49.5454 21.8657 49.5103 21.8385 49.4762 21.8114C49.3746 21.7291 49.2765 21.6397 49.1819 21.5451C49.0102 21.1991 48.7755 20.9162 48.5232 20.7008C48.1501 20.0903 47.9224 19.4115 47.9224 18.843V12.8756C47.9224 12.1635 48.2342 11.518 48.7387 11.0512C49.2432 10.5852 49.9404 10.2961 50.7094 10.2961C52.2492 10.2961 53.4974 11.4506 53.4974 12.8756Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.1821 21.545C48.9334 21.2963 48.71 21.0064 48.5234 20.7007C48.7757 20.9161 49.0104 21.1991 49.1821 21.545Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.9206 22.258V28.5643C30.9206 30.0218 29.6821 31.1727 28.2282 31.065L27.693 31.0256C26.5 30.9371 25.5357 30.02 25.3885 28.8341L24.6992 23.2784H27.6895C29.0576 23.2784 30.2269 22.4279 30.6981 21.2271C30.8418 21.5415 30.9206 21.8901 30.9206 22.258Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.1494 23.2469L23.8603 28.5644C23.8603 30.0218 22.4291 31.1727 20.9752 31.065V31.0256C19.7831 30.9371 18.8187 30.0201 18.6716 28.8341L17.9122 22.7231L17.8929 22.5672C17.8675 22.3657 17.8675 22.1678 17.8903 21.976C17.8973 21.9033 17.9087 21.8306 17.9227 21.7605H17.9726C19.2365 21.7605 20.3016 20.9109 20.6274 19.7512H20.7492C20.927 21.5678 22.3468 23.02 24.1494 23.2469Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.9535 22.5104L17.9123 22.7233L16.8341 28.3132C16.32 29.7374 16.1185 30.7131 14.7811 30.6141L14.2293 30.5773C13.1326 30.4968 12.6168 29.6524 12.4819 28.561L12.3785 22.7951C12.375 22.3151 12.4845 21.8965 12.6606 21.5435C12.9084 21.2956 13.1309 21.0074 13.3166 20.7026C13.5732 20.4828 13.85 20.333 14.097 20.2585C14.4561 21.1397 15.3215 21.7607 16.3322 21.7607H17.8283C17.8528 21.8308 17.8738 21.9026 17.8904 21.9753C17.9316 22.147 17.9535 22.3265 17.9535 22.5104Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3653 21.8106C12.3575 21.9464 12.3434 22.0865 12.3242 22.2284L11.0077 25.9667C10.4778 27.072 8.22065 28.1739 8.22065 28.1739C7.42887 28.0014 7.65395 27.5012 7.6741 26.6017L7.71528 26.2487L8.22591 21.914C8.32226 21.5277 8.60254 21.1055 9.12194 20.7771C9.16485 20.7499 9.2104 20.7228 9.25683 20.6982C9.58353 21.0994 9.94703 21.4708 10.2536 21.7616C10.7896 22.2713 11.662 22.3379 12.2602 21.8921C12.2953 21.8658 12.3312 21.8386 12.3653 21.8106Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.12161 20.7769C8.60222 21.1054 8.32194 21.5276 8.22558 21.9138L7.71496 26.2486C6.98797 27.2515 5.09956 28.1738 5.09956 28.1738C4.30776 28.0012 4.53287 27.5011 4.55301 26.6016L4.83329 24.227L5.04437 22.4367C5.12935 22.096 5.36932 21.4531 5.78536 20.9617C6.24347 21.2394 6.71556 21.412 6.71556 21.412C7.47409 21.412 8.11259 20.9486 8.29215 20.3215H8.30092C8.73185 20.2908 8.98584 20.4721 9.12161 20.7769Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.34431 13.0542V19.9588C8.34431 20.084 8.3268 20.2049 8.29263 20.3214C8.11307 20.9485 7.47457 21.4118 6.71604 21.4118C6.71604 21.4118 6.24394 21.2393 5.78584 20.9616C5.35579 20.7015 4.93799 20.3476 4.93799 19.9588V13.0542C4.93799 12.251 5.66673 11.6011 6.56626 11.6011H6.71604C7.61469 11.6011 8.34431 12.251 8.34431 13.0542Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.78537 20.9619C5.36933 21.4533 5.12936 22.0961 5.04438 22.4369L4.8333 24.2272C4.10718 24.7422 2.82926 25.0417 2.82926 25.0417C2.2906 24.793 2.52972 24.4759 2.68388 23.8444L3.18576 22.4044L3.68325 20.9794C3.76122 20.8235 3.91362 20.5791 4.12209 20.3505C4.55653 20.3006 4.89285 20.079 4.93314 19.8048C4.93404 19.8048 4.93577 19.804 4.93752 19.804V19.959C4.93752 20.3479 5.35532 20.7017 5.78537 20.9619Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.9207 39.673C28.8809 41.5752 26.8412 39.673 20.6669 39.673C14.4925 39.673 3.41179 35.8172 1.81308 34.3263C0.214365 32.8354 1.81308 24.5583 1.81308 24.5583C1.81308 24.5583 2.04775 22.4041 3.18601 22.4041"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.1423 23.2784L36.4521 28.8341C36.305 30.0201 35.3406 30.9371 34.1485 31.0256L33.6125 31.065C32.1594 31.1727 30.9209 30.0218 30.9209 28.5643V22.258C30.9209 21.8901 30.9997 21.5415 31.1434 21.2271C31.6137 22.4279 32.783 23.2784 34.1511 23.2784L37.1423 23.2784Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.9479 22.5672L43.9286 22.7196L43.1701 28.8341C43.023 30.0201 42.0586 30.9371 40.8666 31.0256V31.065C39.4126 31.1727 37.9814 30.0218 37.9814 28.5644L37.6924 23.2478C39.4949 23.02 40.9147 21.5687 41.0925 19.7512H41.2143C41.5401 20.9109 42.6052 21.7605 43.8691 21.7605H43.9181C43.9322 21.8314 43.9435 21.9033 43.9514 21.9768C43.9733 22.1678 43.9733 22.3666 43.9479 22.5672Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.4623 22.7949L49.3599 28.5608C49.2241 29.6522 48.7091 30.4965 47.6116 30.5771L47.0607 30.6139C45.7232 30.7129 45.5208 29.7371 45.0067 28.3129L43.9285 22.7204L43.8882 22.5102C43.8882 22.3271 43.9092 22.1485 43.9513 21.9777C43.9679 21.9041 43.9889 21.8305 44.0135 21.7604H45.5086C46.5193 21.7604 47.3856 21.1394 47.7447 20.2583C47.9917 20.3328 48.2667 20.4825 48.5234 20.7006C48.7756 20.9161 49.0103 21.199 49.182 21.545C49.3572 21.898 49.4667 22.3157 49.4623 22.7949Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.6212 28.1738C53.6212 28.1738 51.3641 27.072 50.8342 25.9666L49.5177 22.2283C49.4985 22.0864 49.4844 21.9472 49.4766 21.8114C49.5107 21.8385 49.5457 21.8657 49.5808 21.892C50.1799 22.3378 51.0514 22.2712 51.5875 21.7615C51.8931 21.4698 52.2584 21.0984 52.5851 20.6973C52.6315 20.7227 52.677 20.7498 52.7199 20.777C53.2394 21.1054 53.5196 21.5276 53.616 21.9139L54.1266 26.2486L54.1678 26.6016C54.1879 27.5011 54.413 28.0013 53.6212 28.1738Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M56.7418 28.1738C56.7418 28.1738 54.8534 27.2515 54.1264 26.2486L53.6158 21.9138C53.5194 21.5276 53.2391 21.1054 52.7197 20.7769C52.8555 20.4721 53.1095 20.2908 53.5404 20.3215H53.5483C53.7288 20.9486 54.3664 21.412 55.1258 21.412C55.1258 21.412 55.5979 21.2385 56.056 20.9618C56.472 21.4531 56.7129 22.096 56.7978 22.4367L57.0089 24.227L57.2884 26.6016C57.3085 27.5011 57.5336 28.0012 56.7418 28.1738Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.0132 25.0416C59.0132 25.0416 57.7352 24.7421 57.0091 24.2271L56.798 22.4368C56.7131 22.096 56.4722 21.4531 56.0562 20.9618C56.4862 20.7008 56.9031 20.3478 56.9031 19.9589V19.803C56.9049 19.8039 56.9066 19.8047 56.9075 19.8047C56.9487 20.0789 57.2841 20.3005 57.7195 20.3504C57.9288 20.579 58.0804 20.8234 58.1583 20.9793L58.6558 22.4043L59.1577 23.8443C59.3118 24.4758 59.551 24.7929 59.0132 25.0416Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.6558 22.4043C59.7935 22.4043 60.0283 24.5581 60.0283 24.5581C60.0283 24.5581 61.6268 32.8352 60.0283 34.326C58.4298 35.8176 47.349 39.6732 41.1749 39.6732C34.9999 39.6732 32.9608 41.5756 30.9209 39.6732"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.03519 13.2502C1.03519 13.2502 0.5965 10.1974 2.81699 10.1974C2.85004 10.1974 2.8876 10.1493 2.93718 10.0652"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.70361 6.15911C10.2039 6.0224 10.8694 5.91123 11.7603 5.85864"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.2816 3.77183C40.231 3.55044 38.5705 3.27692 36.5205 3.2848C33.899 3.29488 33.4603 3.75431 30.9133 3.70889C28.1874 3.66027 28.1693 3.12472 25.4704 3.20344C23.99 3.24662 22.4666 3.45236 21.3715 3.60025C20.2021 3.75818 19.2274 3.92651 18.5269 4.05727"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.291 8.20215C58.5575 9.05549 58.8249 10.1973 58.9842 10.1973C61.2047 10.1973 60.766 13.2501 60.766 13.2501"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.0483 5.75195C48.2647 5.79853 48.5156 5.82557 48.8296 5.82557C49.4095 5.82557 49.9173 5.8451 50.3635 5.88116"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5269 2.13861V4.05712C14.4239 4.79477 14.9317 5.8254 12.9712 5.8254C12.5265 5.8254 12.1253 5.83741 11.7603 5.85845V2.13861C12.5144 1.87569 13.6908 1.5632 15.1556 1.5647C16.6084 1.56621 17.7757 1.87569 18.5269 2.13861Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.0484 1.41767V5.75197C46.7263 5.46202 46.6812 4.38784 41.2817 3.77186V1.41767C42.1396 1.20283 43.2889 1.00151 44.65 1.00001C46.0247 0.998513 47.1845 1.20133 48.0484 1.41767Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.291 8.20222C56.5338 7.69143 55.4191 7.28278 53.7559 7.28278C53.5726 7.28278 53.7124 6.1485 50.3636 5.88109L50.311 4.67619C51.1914 4.48539 52.4849 4.37121 53.6793 4.35168C55.1682 4.32614 55.706 4.41328 57.0837 4.66718L57.291 8.20222Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.70363 5.60475V6.15912C8.13367 6.58879 8.18776 7.28288 8.04503 7.28288C4.17495 7.28288 3.27203 9.49586 2.93701 10.0653V5.83912C4.13289 5.55217 5.34229 5.34033 6.58925 5.34033C7.62738 5.34033 8.66551 5.44249 9.70363 5.60475Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DualArch"
}
</script>
