<template>
  <v-system-bar app class="text-caption white--text" color="secondary">
    <v-img
      contain
      max-width="60"
      class="ml-5"
      :src="require('@/assets/osteon-logo-text.svg')"
    />
  </v-system-bar>
</template>

<script>
export default {
  name: "SystemBar"
}
</script>
