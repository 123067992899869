import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

import SingleArch from "@/components/shared/icons/SingleArch.vue"
import DualArch from "@/components/shared/icons/DualArch.vue"
import SurgicalTool from "@/components/shared/icons/SurgicalTool.vue"
import UploadIcon from "@/components/shared/icons/UploadIcon"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#147BD1",
        secondary: "#333F48",
        accent: "#C7C9C7",
        off: "#F5F5F5"
      },
      light: {
        primary: "#147BD1",
        secondary: "#333F48",
        accent: "#C7C9C7",
        off: "#F5F5F5",
        card: "#E3E3E3"
      }
    },
    icons: {
      iconfont: "mdi"
    }
  },
  icons: {
    values: {
      upload: {
        component: UploadIcon
      },
      singlearch: {
        component: SingleArch
      },
      dualarch: {
        component: DualArch
      },
      surgicaltool: {
        component: SurgicalTool
      }
    }
  }
})
