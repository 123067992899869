var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.retrying)?_c('span',{staticClass:"primary--text"},[_vm._v(" Only upload the two Scan Gauge Scans of a single arch. ")]):_vm._e(),(_vm.retrying)?_c('span',{staticClass:"primary--text"},[_vm._v(" Attempt "+_vm._s(_vm.retryCount + 1)+" - Upload at least one new scan. ")]):_vm._e(),(_vm.retrying && _vm.faceCount)?_c('div',{staticClass:"py-2"},[_vm._v(" Expected Gauge Selection Count: "),_c('strong',[_vm._v(" "+_vm._s(_vm.faceCount)+" ")]),_c('v-btn',{staticClass:"ml-3 elevation-0 rounded-10",attrs:{"small":""},on:{"click":_vm.restartSession}},[_vm._v(" Restart ")])],1):_vm._e(),(_vm.retrying)?_c('v-data-table',{attrs:{"headers":[
      { text: 'Attempt', value: 'attempt' },
      { text: 'Scan 1', value: 'scan1' },
      { text: 'Scan 2', value: 'scan2' }
    ],"items":_vm.uploadedScans,"disable-sort":"","hide-default-footer":""}}):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-0 pt-3"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ScanUploader',{attrs:{"categoryTitle":"Left to Right"},model:{value:(_vm.scan1File),callback:function ($$v) {_vm.scan1File=$$v},expression:"scan1File"}}),_c('v-file-input',{staticClass:"rounded-10 mt-2",attrs:{"rules":_vm.fileRules,"label":"Left-To-Right Scan","dense":"","outlined":"","truncate-length":"25","disabled":!!_vm.bypass},model:{value:(_vm.scan1File),callback:function ($$v) {_vm.scan1File=$$v},expression:"scan1File"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ScanUploader',{attrs:{"categoryTitle":"Right to Left"},model:{value:(_vm.scan2File),callback:function ($$v) {_vm.scan2File=$$v},expression:"scan2File"}}),_c('v-file-input',{staticClass:"rounded-10 mt-2",attrs:{"rules":_vm.fileRules,"label":"Right-To-Left Scan","dense":"","outlined":"","truncate-length":"25","disabled":!!_vm.bypass},model:{value:(_vm.scan2File),callback:function ($$v) {_vm.scan2File=$$v},expression:"scan2File"}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"btn-primary",attrs:{"disabled":_vm.loading ||
              (!_vm.scansChanged && _vm.uploadedScans.length === _vm.retryCount)},on:{"click":_vm.handleContinue}},[_c('span',[_vm._v(" Continue ")])]),(
              _vm.uploadedScans.length &&
              _vm.retrying &&
              !_vm.scansChanged &&
              _vm.uploadedScans.length === _vm.retryCount
            )?_c('v-btn',{staticClass:"elevation-0 rounded-10 ml-3",on:{"click":_vm.reupload}},[_vm._v(" Re-upload Scans ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex-grow-1 ml-5 mr-1"},[_c('span',[_vm._v("Uploading...")]),_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":"","content-class":"rounded-10"},model:{value:(_vm.uploading),callback:function ($$v) {_vm.uploading=$$v},expression:"uploading"}},[(_vm.uploading)?_c('v-card',{staticClass:"card-bg rounded-10"},[_c('v-card-text',{staticClass:"text-center pt-5"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"width":8,"size":200,"value":Math.round((100 * _vm.currentUpload) / 2),"color":"primary"}},[_c('span',[_vm._v(" Uploaded Files "+_vm._s(_vm.currentUpload)+" of 2 "),_c('br'),_vm._v(" "+_vm._s(Math.round((100 * _vm.currentUpload) / 2))+"% ")])])],1)],1),_c('p',{class:['mt-5']},[_vm._v(" Please wait for your uploads to complete before continuing. ")]),(_vm.uploading)?_c('v-progress-linear',{staticClass:"rounded-lg pb-3",attrs:{"stream":"","buffer-value":"0","value":Math.round((100 * _vm.currentUpload) / 2),"color":"success"}}):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }