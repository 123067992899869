<template>
  <v-card-text class="black--text">
    <p class="primary--text text-body-1 font-weight-medium">
      Scanning Technique
    </p>
    <v-card color="off" class="pa-1" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        7. Gauge Movement
      </v-card-title>
      <v-card-text class="pb-2">
        Ensure every Scan Gauge is tightened so it is completely seated to the
        MUA. Gauges must not move between the 2 scans.
      </v-card-text>
      <div class="d-flex justify-center">
        <v-img
          src="@/assets/suggestions/gauge-movement.svg"
          width="200"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
    <v-card color="off" class="pa-1 mt-2" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        8. Multi-Unit Abutment Movement
      </v-card-title>
      <v-card-text class="pb-3">
        Ensure the Multi-Unit Abutments are seated to the implant and correctly
        torqued to the implant.
      </v-card-text>
      <div class="d-flex justify-center pb-3">
        <v-img
          src="@/assets/suggestions/mua-movement.svg"
          width="135"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
  </v-card-text>
</template>

<script>
export default {
  name: "SuggestionPage4"
}
</script>
