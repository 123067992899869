<template>
  <div
    style="
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      row-gap: 25px;
    "
  >
    <v-fade-transition>
      <v-row style="width: 100%" class="ma-0 pa-0" v-show="!transition">
        <v-col class="fill-height ma-0 pa-0">
          <v-card class="fill-height rounded-10">
            <div ref="scan1View"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition>
      <v-row style="width: 100%" class="ma-0 pa-0" v-show="!transition">
        <v-col class="fill-height ma-0 pa-0">
          <v-card class="fill-height rounded-10">
            <div ref="scan2View"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"

export default {
  name: "SelectionDivs",

  scan1View: null,
  scan2View: null,

  mounted() {
    this.scan1View = NCFrontend.createView("scan1View", this.$refs.scan1View)
    this.scan2View = NCFrontend.createView("scan2View", this.$refs.scan2View)
    NCFrontend.add(this.scan1View, this.scan2View)
    this.scan1View.setVisualisation(
      new NCFrontend.Visualisations.ScanGaugeSelect(this.scan1View.element, {
        mesh: this.addScansResponse.scan1.mesh,
        detectedPlanes: this.addScansResponse.scan1.detectedPlanes
      })
    )
    this.scan2View.setVisualisation(
      new NCFrontend.Visualisations.ScanGaugeSelect(this.scan2View.element, {
        mesh: this.addScansResponse.scan2.mesh,
        detectedPlanes: this.addScansResponse.scan2.detectedPlanes
      })
    )
    setTimeout(() => {
      this.setTransition(false)
    }, 300)
  },

  // This is never called
  unmounted() {
    NCFrontend.remove(this.scan1View, this.scan2View)
  },

  methods: {
    ...mapActions(["setTransition"])
  },

  computed: {
    ...mapGetters(["addScansResponse", "transition", "pageState"])
  }
}
</script>
