<template>
  <v-card-text class="black--text">
    <v-card color="off" class="pa-1" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        3. Angulation / Vertical Gap
      </v-card-title>
      <v-card-text class="pb-1">
        <p>Ensure minimal space between tissue and Gauges.</p>
        <p>
          <em>
            Impression material can be added to connect the base of the Gauge to
            the tissue.
          </em>
        </p>
      </v-card-text>
      <div class="d-flex justify-center pb-3">
        <v-img
          src="@/assets/suggestions/angulation.svg"
          width="170"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
    <v-card color="off" class="pa-1 mt-2" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        4. Use Retractors
      </v-card-title>
      <v-card-text class="pb-0">
        <p>
          There cannot be any Lip, Tongue, Cheek or Fingers interfering with the
          scan.
        </p>
        <p>
          <em>
            Optragate, Umbrella or similar style retractors are always
            recommended.*
          </em>
        </p>
      </v-card-text>
      <div class="d-flex justify-center pb-3">
        <v-img
          src="@/assets/suggestions/retractors.svg"
          width="220"
          style="flex-grow: 0"
        />
      </div>
      <v-card-text style="font-size: 7px; line-height: normal" class="py-0">
        *All product names, brands and registered trademarks are the property of
        their respective owners.
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
export default {
  name: "SuggestionPage2"
}
</script>
