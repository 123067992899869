<template>
  <v-card class="pa-2 rounded-10">
    <v-card-title class="title-font pb-0" style="font-weight: 600">
      ORDER FORM
    </v-card-title>
    <v-divider class="mx-4 mt-2" />
    <v-row v-if="initialising" class="pa-8" justify="center">
      <v-progress-circular size="60" indeterminate />
    </v-row>
    <v-form ref="orderForm" v-if="!initialising">
      <v-card-text>
        <v-card flat>
          <v-card-text v-if="questions?.length === 0" class="pa-5">
            There are no Questions for this Order Form
          </v-card-text>

          <v-card-text v-if="questions?.length" class="pa-0">
            <v-card
              v-for="(question, index) in questions"
              :key="index"
              flat
              class="mb-5"
            >
              <v-card-title class="mx-0 px-0 pt-0">
                <v-icon small v-if="question.mandatory" class="mr-1">
                  *
                </v-icon>
                {{ question.name }}
              </v-card-title>

              <v-card-subtitle>
                {{ question.description }}
              </v-card-subtitle>

              <v-card flat class="rounded-10">
                <v-text-field
                  v-if="question.widget === 'text_field'"
                  v-model="orderDetails.responses[index].value"
                  :label="`Answer`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="question.mandatory ? rule : []"
                  :disabled="submitting"
                />
                <v-textarea
                  v-if="question.widget === 'text_area'"
                  v-model="orderDetails.responses[index].value"
                  label="Answer"
                  rows="1"
                  hide-details="auto"
                  auto-grow
                  outlined
                  dense
                  :rules="question.mandatory ? rule : []"
                  :disabled="submitting"
                />
                <v-select
                  v-if="question.widget === 'drop_down_picker'"
                  v-model="orderDetails.responses[index].value"
                  label="Answer"
                  :items="availableResponses(question)"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="question.mandatory ? rule : []"
                  :disabled="submitting"
                />
                <MultiUnitConfirmation
                  v-if="question.widget === 'tooth_picker'"
                  v-model="orderDetails.responses[index].value"
                  :responses="availableResponses(question)"
                  :interactive="true"
                />
                <DatetimePicker v-if="question.widget === 'datetime_picker'" />
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn elevation="0" text color="primary" @click="submitOrder">
          <span v-if="!submitting"> Submit </span>
          <v-progress-circular v-if="submitting" indeterminate size="24" />
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import MultiUnitConfirmation from "./OrderForm/MultiUnitConfirmation.vue"
import DatetimePicker from "./OrderForm/DatetimePicker.vue"

export default {
  name: "OrderForm",

  components: { MultiUnitConfirmation, DatetimePicker },

  data() {
    return {
      initialising: false,
      submitting: false,
      rule: [(v) => !!v || "Required"]
    }
  },

  async mounted() {
    this.initialising = true
    if (this.questions === null) {
      this.setQuestions(await client.instance.orders.getQuestions())
      const responses = []
      this.questions.forEach((question, index) => {
        responses.push({
          order: index + 1,
          name: question.name,
          description: question.description,
          widget: question.widget,
          value: question.widget === "tooth_picker" ? [] : ""
        })
      })

      this.setOrderDetails({
        ...this.orderDetails,
        responses: responses
      })
    }

    this.initialising = false
  },

  computed: {
    ...mapGetters(["orderDetails", "orderUid", "questions"])
  },

  methods: {
    ...mapActions(["setOrderDetails", "setQuestions"]),

    availableResponses(question) {
      return question.metadata?.responses?.map((response) => {
        return {
          text: response.name,
          value: response.name
        }
      })
    },

    async submitOrder() {
      if (this.$refs.orderForm.validate()) {
        if (this.submitting) return
        this.submitting = true
        const order = await client.instance.orders.submitOrder(this.orderUid)
        this.submitting = false

        this.setOrderDetails(order)
      }
    }
  }
}
</script>
