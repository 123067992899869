export function debounce(func, delay) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(func, delay, ...args)
  }
}

export const defaultColours = {
  off: "#F5F6F6",
  info: "#2196F3",
  error: "#FF5252",
  accent: "#C7C9C7",
  primary: "#147BD1",
  success: "#4CAF50",
  warning: "#FB8C00",
  primary2: "#003ACD",
  success2: "#00AB11",
  secondary: "#333F48",
  card: "#E3E3E3",
  card2: "#EEEFEE",
  background1: "#50B5FF50",
  background2: "#A9A9A920",
  backgroundDir: "45deg"
}

function getLuminance(r, g, b) {
  // Convert RGB values to sRGB
  r = r / 255.0
  g = g / 255.0
  b = b / 255.0

  // Apply gamma correction
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  // Calculate luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

function getContrastRatio(l1, l2) {
  return (l1 + 0.05) / (l2 + 0.05) > 1
    ? (l1 + 0.05) / (l2 + 0.05)
    : (l2 + 0.05) / (l1 + 0.05)
}

export function getTextColour(bgColour) {
  // Extract RGB components from hex color
  const r = parseInt(bgColour.slice(1, 3), 16)
  const g = parseInt(bgColour.slice(3, 5), 16)
  const b = parseInt(bgColour.slice(5, 7), 16)

  const bgLuminance = getLuminance(r, g, b)

  const blackLuminance = getLuminance(0, 0, 0)
  const whiteLuminance = getLuminance(255, 255, 255)

  const contrastWithWhite = getContrastRatio(bgLuminance, whiteLuminance)

  if (contrastWithWhite > 2.5) return "white"

  const contrastWithBlack = getContrastRatio(bgLuminance, blackLuminance)

  return contrastWithBlack > contrastWithWhite ? "black" : "white"
}
