import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import VueCookies from "vue-cookies"
import router from "./router"
import store from "./store"
import axios from "./plugins/axios"

Vue.config.productionTip = false

Vue.use(axios)
Vue.use(VueCookies, { expires: "1d" })
document.title = "NC Lab Ordering"

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
