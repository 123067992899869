<template>
  <div
    style="
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: min-content auto;
    "
  >
    <v-row>
      <v-col>
        <p
          class="title-font my-2 mb-n2"
          style="font-size: 17px"
          v-if="settings.manage_clients && !completed"
        >
          {{ client.name }}
        </p>
      </v-col>
    </v-row>

    <v-row v-if="!completed" style="width: 100%">
      <template v-for="(item, index) in cards">
        <v-spacer
          v-if="item.component === 'OrderForm'"
          :key="`spacer${index}`"
        />
        <v-col :cols="item.cols" :key="`home${index}`">
          <component :is="item.component" />
        </v-col>
      </template>
    </v-row>

    <v-fade-transition hide-on-leave>
      <v-row v-if="completed" justify="center" class="mt-5">
        <v-col cols="5">
          <CompleteCard />
        </v-col>
      </v-row>
    </v-fade-transition>

    <v-snackbar v-model="error" color="error" class="d-flex">
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        {{ errorMessage }}
        <v-spacer />
        <v-btn icon @click="setErrorMessage(null)">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { debounce } from "@/utils"
import MainCard from "./HomePage/MainCard.vue"
import CompleteCard from "./HomePage/CompleteCard.vue"
import SuggestedSolutions from "./HomePage/SuggestedSolutions.vue"
import ConnectBranding from "./HomePage/ConnectBranding.vue"
import OrderForm from "./HomePage/OrderForm.vue"
import SelectionDivs from "./HomePage/SelectionDivs.vue"
import ValidatedDiv from "./HomePage/ValidatedDiv.vue"
import client from "@/lib/ApiClient"

export default {
  name: "HomePage",

  components: {
    MainCard,
    CompleteCard,
    SuggestedSolutions,
    OrderForm,
    ConnectBranding,
    SelectionDivs,
    ValidatedDiv
  },

  watch: {
    orderDetails: {
      handler(value) {
        if (value.submitted_at) return
        this.debounce()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "pageState",
      "settings",
      "client",
      "orderDetails",
      "orderUid",
      "errorMessage"
    ]),

    completed() {
      return !!this.orderDetails.submitted_at
    },

    cards() {
      const remainingCols = this.pageState.reduce((acc, val) => {
        return acc - val.cols
      }, 12)
      const cards = [{ cols: remainingCols, component: "MainCard" }]

      cards.push(...this.pageState)

      return cards
    },

    error: {
      get() {
        return !!this.errorMessage
      },
      set(value) {
        if (!value) this.setErrorMessage(null)
      }
    }
  },

  methods: {
    ...mapActions(["setOrderUid", "setErrorMessage"]),

    async createOrUpdateOrder() {
      if (
        !this.orderDetails.patient_reference ||
        !(this.orderDetails.client_uid || this.orderDetails.client_name)
      )
        return

      if (this.orderUid) {
        await client.instance.orders.updateOrder(
          this.orderUid,
          this.orderDetails
        )
      } else {
        const response = await client.instance.orders.createOrder(
          this.orderDetails
        )
        this.setOrderUid(response.uid)
      }
    }
  },

  created() {
    this.debounce = debounce(this.createOrUpdateOrder, 500)
  }
}
</script>
