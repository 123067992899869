<template>
  <v-card-text class="black--text">
    <p class="primary--text text-body-1 font-weight-medium">
      Scanning Technique
    </p>
    <v-card color="off" class="pa-1" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        5. Move in a Single Direction
      </v-card-title>
      <v-card-text class="pb-2">
        Do not scan over the areas that are already captured. Discrepancy may
        occur.
      </v-card-text>
      <div class="d-flex justify-center pb-5">
        <v-img
          src="@/assets/suggestions/single-direction.svg"
          width="240"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
    <v-card color="off" class="pa-1 mt-2" flat>
      <v-card-title class="primary--text text-body-1 font-weight-medium">
        6. Remove Moisture
      </v-card-title>
      <v-card-text class="pb-2">
        Ensure sufficient overlap of Scan Gauges so the scanner's field of view
        can see more than one Gauge at any time.
      </v-card-text>
      <div class="d-flex justify-center pb-5">
        <v-img
          src="@/assets/suggestions/remove-moisture.svg"
          width="230"
          style="flex-grow: 0"
        />
      </div>
    </v-card>
  </v-card-text>
</template>

<script>
export default {
  name: "SuggestionPage3"
}
</script>
